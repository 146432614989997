import { injectable } from 'inversify';
import { HttpMethod, HttpRequest, HttpResponse } from '@/services/http/https-service-types';
import { SingleBatchResponse } from '@/services/batch/batch-service-types';
import { Device } from '@/services/device/device-service-types';
import { IDeviceService } from '@/services/device/device-service-interface';

@injectable()
export class DeviceService implements IDeviceService {
  //--------------------------------------------------
  // Constructor
  //--------------------------------------------------
  constructor() {}

  //--------------------------------------------------------------
  // constructGetDeviceInfoRequest
  //--------------------------------------------------------------
  constructGetDeviceInfoRequest(clientKey: string, responseCallback: (response: HttpResponse<Device>) => void): HttpRequest {
    if (!clientKey) {
      console.error('Cannot fetch device info as no client key found.');
      return;
    }

    const url = `/api/v1/clients/${clientKey}/device`;

    return {
      url,
      method: HttpMethod.GET,
      batchResponseTypingCallback: (response: SingleBatchResponse<Device>) => response.body as Device,
      responseCallback,
    };
  }

  //--------------------------------------------------------------
  // constructValidateDeviceRequest
  //--------------------------------------------------------------
  constructValidateDeviceRequest(clientKey: string, deviceID: string, securityPin: string, responseCallback: (response: HttpResponse<void>) => void): HttpRequest {
    if (!clientKey || !deviceID || !securityPin) {
      throw new Error('The client key, device ID and security pin must all be provided in order to validate device.');
    }

    const url = `/api/v1/clients/${clientKey}/${deviceID}/${securityPin}`;

    return {
      url,
      method: HttpMethod.GET,
      batchResponseTypingCallback: (response: SingleBatchResponse<string[]>) => response.body as string[],
      responseCallback,
    };
  }

  //--------------------------------------------------------------
  // constructActivateDeviceRequest
  //--------------------------------------------------------------
  constructActivateDeviceRequest(clientKey: string, deviceKey: string, devicePin: string, responseCallback: (response: HttpResponse<void>) => void): HttpRequest {
    const url = `/api/v1/clients/${clientKey}/device/${deviceKey}/${devicePin}`;

    return {
      url,
      method: HttpMethod.PUT,
      batchResponseTypingCallback: (response: SingleBatchResponse<void>) => response.body,
      responseCallback,
    };
  }

  //--------------------------------------------------------------
  // constructUpdateDeviceRequest
  //--------------------------------------------------------------
  constructUpdateDeviceRequest(clientKey: string, deviceKey: string, devicePin: string, responseCallback: (response: HttpResponse<void>) => void): HttpRequest {
    const url = `/api/v1/clients/${clientKey}/device/${deviceKey}/${devicePin}`;

    return {
      url,
      method: HttpMethod.POST,
      batchResponseTypingCallback: (response: SingleBatchResponse<void>) => response.body,
      responseCallback,
    };
  }
}
