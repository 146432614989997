import { injectable } from 'inversify';
import { IAuthService } from '@/services/auth/auth-service-interface';
import { UserOptionsEnum, userOptionsMap } from '@/services/user/user-service-types';

@injectable()
export class MockAuthService implements IAuthService {
  //--------------------------------------------------
  // Fields
  //--------------------------------------------------

  private _loginAction: any;
  private _logoutAction: any;

  constructor() {
    this._loginAction = () => { };
    this._logoutAction = () => { };
  }

  forbidden: () => Promise<void>;

  logoutIdentityProvider: () => Promise<void>;

  async updateLogoutUrl(logoutUrl: string) { }

  handleServerError: () => void;

  async init() { }

  async login() {
    this._loginAction();
  }

  async logout() {
    this._logoutAction();
  }

  async isAuthenticated(userOptions: UserOptionsEnum = UserOptionsEnum.DEFAULT) {
    const { slideSession } = userOptionsMap[`${userOptions}`];
    if (slideSession) {
      this._loginAction();
    }
    const info = await this.getAuthenticationInfo(userOptions);
    return info.SessionExpirationSec > 0;
  }

  async getAuthenticationInfo(userOptions: UserOptionsEnum = UserOptionsEnum.DEFAULT) {
    let sessionTime = 30;
    const { slideSession } = userOptionsMap[`${userOptions}`];

    if (slideSession) {
      sessionTime = 30;
    }

    return {
      SessionExpirationSec: sessionTime,
      LogoutUrl: null,
    };
  }

  onLogin(action: any) {
    this._loginAction = () => {
      action();
    };
  }

  onLogout(action: any) {
    this._logoutAction = () => {
      action();
    };
  }
}
