const REPORT_DEFAULT_FILENAME = 'clientdetailreport.pdf';

export function downloadPDF(pdf: File, fileName: string) {
  // If no PDF is found, return
  if (!pdf) {
    throw new Error('No PDF to download');
  }

  // Create a Blob from the pdf
  const blob = new Blob([pdf], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);

  // Create an invisible link element
  const link = document.createElement('a');

  // Populate the link element
  link.href = url;
  link.download = fileName || REPORT_DEFAULT_FILENAME;

  // Anchor the link in the doc (some browsers require this)
  document.body.appendChild(link);

  // Click the link to download and then immediately remove it from the DOM
  link.click();
  link.remove();

  // Remove the URL to reduce memory load
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
}
