import { inject, injectable } from 'inversify';
import { Pageable, PageableWrapper } from '@soberlink/soberlink-vue-library';
import { BaseRelationship, ClientRelationship, Connection, ConnectionGroup, ConnectionGroupByType, ConnectionRelationshipType, ConnectionStatus } from '@/services/connection/connection-service-types';

//-----------------------------------------------
// Http Service
//-----------------------------------------------
import type { IHttpService } from '@/services/http/http-service-interface';
import { HttpMethod, HttpRequest, HttpResponse, TYPES as httpServiceTYPES } from '@/services/http/https-service-types';

//-----------------------------------------------
// Activity Service
//-----------------------------------------------
import type { IActivityService } from '@/services/activity/activity-service-interface';
import { TYPES as activityServiceTYPES } from '@/services/activity/activity-service-types';

//-----------------------------------------------
// Wrapper service
//-----------------------------------------------
import { IConnectionService } from '@/services/connection/connection-service-interface';

//-----------------------------------------------
// PageableUtility Service
//-----------------------------------------------
import { TYPES as pageableUtilityServiceTYPES } from '@/services/utility/pageable-service-types';
import type { IPageableUtilityService } from '@/services/utility/pageable-service-interface';
import { generateConnectionQueryString, relationshipMap } from '@/services/connection/connection-service-utils';
import { ClientStatus } from '@/services/client/client-service-types';

//====================================================================
// Mock Implementation
//====================================================================

@injectable()
export class MockConnectionService implements IConnectionService {
  //-----------------------------------------------
  // Fields
  //-----------------------------------------------

  private _httpService: IHttpService;
  private _activityService: IActivityService;
  private _pageableUtilityService: IPageableUtilityService;
  private _connections: Connection[]; // Set an internal connections array so that we can maintain state while mocking
  private _connectionGroups: ConnectionGroup[]; // Set an internal connection groups array so that we can maintain state while mocking

  //--------------------------------------------------
  // Constructor
  //--------------------------------------------------

  constructor(
    @inject(httpServiceTYPES.IHttpService) httpService: IHttpService,
    @inject(activityServiceTYPES.IActivityService) activityService: IActivityService,
    @inject(pageableUtilityServiceTYPES.IPageableUtilityService) pageableUtilityService: IPageableUtilityService
  ) {
    this._httpService = httpService;
    this._activityService = activityService;
    this._pageableUtilityService = pageableUtilityService;
    this._connections = getListOfAllConnections();
    this._connectionGroups = getListOfAllConnectionGroups();
  }

  //--------------------------------------------------------------
  // constructConnectionGroupsRequest
  //--------------------------------------------------------------

  constructConnectionsForContactRequest(
    group: ConnectionGroupByType,
    relationship: ConnectionRelationshipType,
    status: ConnectionStatus | undefined,
    clientStatus: ClientStatus[] | undefined,
    pageable: Pageable,
    responseCallback: (response: HttpResponse<PageableWrapper<ConnectionGroup>>) => void
  ): HttpRequest {
    const pageStartingIdx = pageable.pageIndex * pageable.pageSize;
    const items = this._connectionGroups.slice(pageStartingIdx, pageStartingIdx + pageable.pageSize);

    // Generate the query string
    const queryString = generateConnectionQueryString(relationship, status, clientStatus, group);

    const mockedResponse = {
      items,
      totalItems: this._connectionGroups.length,
      pageSize: pageable.pageSize,
    };

    return {
      url: `/mock/connections?${queryString}`,
      method: HttpMethod.GET,
      batchResponseTypingCallback: () => mockedResponse,
      responseCallback,
    };
  }

  //--------------------------------------------------------------
  // constructConnectionRequest
  //--------------------------------------------------------------

  constructConnectionsForClientRequest(
    clientKey: string,
    relationship: ConnectionRelationshipType,
    status: ConnectionStatus,
    clientStatus: ClientStatus[] | undefined,
    pageable: Pageable,
    responseCallback: (response: HttpResponse<PageableWrapper<Connection>>) => void
  ): HttpRequest {
    if (!clientKey) {
      console.error('Must include clientKey in constructConnectionsForClientRequest');
      return;
    }

    // Generate the query string
    const queryString = generateConnectionQueryString(relationship, status, clientStatus);

    let connections = relationship !== undefined ? this._connections.filter((connection: Connection) => connection.relationship?.relationshipType === relationship) : this._connections;

    // Set the connections' status to match the given status, if provided
    if (status !== undefined) {
      connections = connections.map((connection: Connection) => {
        return { ...connection, status };
      });
    }

    const pageStartingIdx = pageable.pageIndex * pageable.pageSize;
    const items = connections.slice(pageStartingIdx, pageStartingIdx + pageable.pageSize);

    const mockedResponse = {
      items,
      totalItems: connections.length,
      pageSize: pageable.pageSize,
    };

    return {
      url: `/mock/client/${clientKey}/connections?${queryString}`,
      method: HttpMethod.GET,
      batchResponseTypingCallback: () => mockedResponse,
      responseCallback,
    };
  }

  //--------------------------------------------------------------
  // constructConnectionByKeyRequest
  //--------------------------------------------------------------

  constructConnectionByKeyRequest(connectionKey: string, responseCallback: (response: HttpResponse<Connection>) => void) {
    const connection = this._connections.find((connection: Connection) => connection?.connectionKey === connectionKey);
    return {
      url: `/mock/connections/${connectionKey}`,
      method: HttpMethod.GET,
      batchResponseTypingCallback: () => connection,
      responseCallback,
    };
  }

  //--------------------------------------------------------------
  // constructConnectionStatusUpdateRequest
  //--------------------------------------------------------------

  constructConnectionStatusUpdateRequest(connectionKey: string, connectionStatus: ConnectionStatus): HttpRequest {
    // Filter out updated invitation
    this._connections = this._connections.filter((connection: Connection) => connection.connectionKey !== connectionKey);
    return {
      url: `/mock/connections/${connectionKey}/status/${connectionStatus}`,
      method: HttpMethod.PUT,
      batchResponseTypingCallback: () => `Connection ${connectionKey} updated to ${connectionStatus}`,
    };
  }

  //--------------------------------------------------------------
  // constructAcceptAllInvitesRequest
  //--------------------------------------------------------------

  constructAcceptAllInvitesRequest(): HttpRequest {
    const acceptAllInvites = () => {
      // Filter out any invitations
      this._connections = this._connections.filter((connection: Connection) => connection.status === ConnectionStatus.PENDING_ACCEPTANCE);
    };

    return {
      url: `/mock/connections/status/${ConnectionStatus.PENDING_ACCEPTANCE}/${ConnectionStatus.ACTIVE}`,
      method: HttpMethod.PUT,
      batchResponseTypingCallback: () => {},
      responseCallback: () => acceptAllInvites(),
    };
  }
}

/*
 * Get a set of connections upon initialization. This is important while mocking so that we
 * can locate specific users on load in order to as accurately mock real behavior as possible
 */
function getListOfAllConnections() {
  const connections = [];

  for (let i = 0; i < 4; i++) {
    for (let j = 0; j < firstNames?.length; j++) {
      const conn: Connection = {
        connectionKey: `000-000-${i}-${j}-type-${i}`,
        userKey: `000-000-${i}-${j}-type-${i}`,
        status: 1,
        createDate: new Date(`2018-08-20T19:19:19.179+00:00`),
        relationship: buildRelationshipObj(i, j),
      };
      connections.push(conn);
    }
  }

  return connections;
}

function getListOfAllConnectionGroups() {
  const connectionGroups = [];

  for (let i = 1; i < 4; i++) {
    for (let j = 0; j < firstNames?.length; j++) {
      // Randomize the chance of there being multiple histories
      const hasMultipleHistories = Math.floor(Math.random() * 5) === 0; // 1 in 5 chance

      // If the user does not have multiple histories, show 1 history, otherwise, randomize a number between 2 and 5
      const numberOfHistories = hasMultipleHistories ? Math.floor(Math.random() * 3) + 2 : 1;

      const items: Connection[] = [];
      for (let k = 0; k < numberOfHistories; k++) {
        items.push({
          connectionKey: `000-000-${i}-${j}-type-${i}`,
          userKey: `000-000-${i}-${j}-type-${i}`,
          status: 1,
          createDate: new Date(`2018-08-20T19:19:19.179+00:00`),
          relationship: buildRelationshipObj(ConnectionRelationshipType.CLIENT, j, k),
        });
      }

      connectionGroups.push({
        group: {
          userKey: `000-000-${i}-${j}-type-${i}`,
          timeZoneName: 'Pacific Standard Time',
          firstName: firstNames[j],
          lastName: lastNames[j],
        } as BaseRelationship,
        items,
      });
    }
  }

  return connectionGroups;
}

const lastNames: string[] = new Array(3).fill(['Smith', 'Johnson', 'Williams', 'Brown']).flat();
const firstNames: string[] = new Array(3).fill(['Robert', 'Patricia', 'John', 'Jennifer']).flat();

function buildRelationshipObj(i: number, j: number, k: number = 1) {
  // Add in the BaseRelationship fields, present for any type of relationship
  const userKey = `000-000-${i}-${j}-type-${i}`;
  let relationshipSpecificObj: BaseRelationship = {
    userKey,
    timeZoneName: 'Pacific Standard Time',
    firstName: firstNames[j],
    lastName: lastNames[j],
    phoneNumber: {
      number: `${i}142${i}${j}2${i}1${j}`,
      region: {
        countryCode: 1,
      },
    },
  };

  // If the relationship type is that of Client, add the necessary extra fields
  if (i === 1) {
    // Ensure the first client's status is `Active`. Otherwise, randomise the result.
    let clientStatus;
    if (k === 0) {
      clientStatus = ClientStatus.ACTIVE;
    } else {
      const randomizedStatus = Math.round(Math.random() * 5) as ClientStatus;
      // Ensure that only the first (0th) client can be active
      clientStatus = randomizedStatus === ClientStatus.ACTIVE ? ClientStatus.ARCHIVED : randomizedStatus;
    }

    relationshipSpecificObj = {
      ...relationshipSpecificObj,
      clientKey: userKey,
      deviceId: `000-000-${i}-${j}-type-${i}`,
      alias: `${firstNames[j]} ${lastNames[j]} - Device 1`,
      clientStatus,
    } as ClientRelationship;
  }

  return {
    relationshipType: i as ConnectionRelationshipType,
    [`${relationshipMap[i]}`]: relationshipSpecificObj,
  };
}
