import { inject, injectable } from 'inversify';
import { ConnectionRelationshipType, ConnectionStatus, ConnectionGroupByType, Connection, ConnectionGroup } from '@/services/connection/connection-service-types';
import { PageableWrapper } from '@soberlink/soberlink-vue-library';

//-----------------------------------------------
// Http Service
//-----------------------------------------------
import type { IHttpService } from '@/services/http/http-service-interface';
import { HttpMethod, HttpRequest, HttpResponse, TYPES as httpServiceTYPES } from '@/services/http/https-service-types';

//-----------------------------------------------
// activity Service
//-----------------------------------------------
import type { IActivityService } from '@/services/activity/activity-service-interface';
import { TYPES as activityServiceTYPES } from '@/services/activity/activity-service-types';

//-----------------------------------------------
// Wrapper service
//-----------------------------------------------
import { IConnectionService } from '@/services/connection/connection-service-interface';

//-----------------------------------------------
// PageableUtility Service
//-----------------------------------------------
import type { IPageableUtilityService } from '@/services/utility/pageable-service-interface';
import { TYPES as pageableUtilityServiceTYPES } from '@/services/utility/pageable-service-types';
import { generateConnectionQueryString } from '@/services/connection/connection-service-utils';
import { SingleBatchResponse } from '@/services/batch/batch-service-types';
import { ClientStatus } from '@/services/client/client-service-types';

@injectable()
export class ConnectionService implements IConnectionService {
  //-----------------------------------------------
  // Fields
  //-----------------------------------------------

  private _httpService: IHttpService;
  private _activityService: IActivityService;
  private _pageableUtilityService: IPageableUtilityService;

  //--------------------------------------------------
  // Constructor
  //--------------------------------------------------

  constructor(
    @inject(httpServiceTYPES.IHttpService) httpService: IHttpService,
    @inject(activityServiceTYPES.IActivityService) activityService: IActivityService,
    @inject(pageableUtilityServiceTYPES.IPageableUtilityService) pageableUtilityService: IPageableUtilityService
  ) {
    this._httpService = httpService;
    this._activityService = activityService;
    this._pageableUtilityService = pageableUtilityService;
  }

  //--------------------------------------------------------------
  // constructConnectionGroupsRequest
  //--------------------------------------------------------------

  constructConnectionsForContactRequest(
    group: ConnectionGroupByType | undefined,
    relationship: ConnectionRelationshipType | undefined,
    status: ConnectionStatus | undefined,
    clientStatus: ClientStatus[] | undefined,
    pageable,
    responseCallback: (response: HttpResponse<PageableWrapper<ConnectionGroup>>) => void
  ): HttpRequest {
    // Generate the query string
    const queryString = generateConnectionQueryString(relationship, status, clientStatus, group);

    // Endpoint
    const url = `/api/v1/connections?${queryString}&${this._pageableUtilityService.getQueryString(pageable)}`;

    return {
      url,
      method: HttpMethod.GET,
      batchResponseTypingCallback: (response: SingleBatchResponse<PageableWrapper<ConnectionGroup>>) => response?.body as PageableWrapper<ConnectionGroup>,
      responseCallback,
    };
  }

  //--------------------------------------------------------------
  // constructConnectionRequest
  //--------------------------------------------------------------
  constructConnectionsForClientRequest(
    clientKey: string,
    relationship: ConnectionRelationshipType | undefined,
    status: ConnectionStatus | undefined,
    clientStatus: ClientStatus[] | undefined,
    pageable,
    responseCallback: (responseBody: PageableWrapper<Connection>) => void
  ): HttpRequest {
    if (!clientKey) {
      console.error('Must include clientKey in constructConnectionsForClientRequest');
      return;
    }

    // Generate the query string
    const queryString = generateConnectionQueryString(relationship, status, clientStatus);

    // Endpoint
    const url = `/api/v1/clients/${clientKey}/connections?${queryString}&${this._pageableUtilityService.getQueryString(pageable)}`;

    return {
      url,
      method: HttpMethod.GET,
      batchResponseTypingCallback: (response: SingleBatchResponse<PageableWrapper<Connection>>) => response?.body as PageableWrapper<Connection>,
      responseCallback,
    };
  }

  //--------------------------------------------------------------
  // constructConnectionByKeyRequest
  //--------------------------------------------------------------

  constructConnectionByKeyRequest(connectionKey: string, responseCallback: (response: HttpResponse<Connection>) => void): HttpRequest {
    const url = `/api/v1/connections/${connectionKey}`;
    return {
      url,
      method: HttpMethod.GET,
      batchResponseTypingCallback: (response: SingleBatchResponse<Connection>) => response?.body as Connection,
      responseCallback,
    };
  }

  //--------------------------------------------------------------
  // constructConnectionStatusUpdateRequest
  //--------------------------------------------------------------

  constructConnectionStatusUpdateRequest(connectionKey: string, connectionStatus: ConnectionStatus): HttpRequest {
    const url = `/api/v1/connections/${connectionKey}/status/${connectionStatus}`;
    return {
      url,
      method: HttpMethod.PUT,
    };
  }

  //--------------------------------------------------------------
  // constructConnectionStatusUpdateRequest
  //--------------------------------------------------------------

  constructAcceptAllInvitesRequest(): HttpRequest {
    const url = `/api/v1/connections/status/${ConnectionStatus.PENDING_ACCEPTANCE}/${ConnectionStatus.ACTIVE}`;
    return {
      url,
      method: HttpMethod.PUT,
    };
  }
}
