import { injectable } from 'inversify';

//-----------------------------------------------
// Http Service
//-----------------------------------------------
import type { IHttpService } from '@/services/http/http-service-interface';
import { ErrorHandlers, HttpRequest, HttpResponse } from '@/services/http/https-service-types';
import { handleHttpError } from '@/utils/error-handling-utils';

//====================================================================
// Mock Implementation
//====================================================================

@injectable()
export class MockHttpService implements IHttpService {
  //--------------------------------------------------
  // Constructor
  //--------------------------------------------------
  constructor() {}

  //---------------------------------------
  // sendBatchRequest
  //---------------------------------------
  async sendRequest(request: HttpRequest, customErrorHandlers?: ErrorHandlers): Promise<any> {
    let data;

    // Error-mocking logic
    const shouldMockError = false; // If we want to mock errors, set to true. Otherwise, set to false
    const status = 500;

    if (request.batchResponseTypingCallback) {
      data = request.batchResponseTypingCallback(undefined);
    }

    if (request.responseCallback) {
      request.responseCallback({ data, headers: {}, status: 200 } as HttpResponse<any>);
    }

    if (shouldMockError) {
      const mockError = {
        response: {
          status,
        },
      };
      return handleHttpError(mockError, customErrorHandlers);
    }

    return {
      data,
      status: 200,
      headers: {},
    };
  }
}
