export enum RouteName {
  HOME = '/',
  BILLING = 'billing',
  CONNECTIONS = 'connections',
  CLIENT_DRILLDOWN = 'client-drilldown',
  MY_MONITORING = 'my-monitoring',
  DEVICE = 'device',
  CLIENT_DRILLDOWN_REPORTS = 'reports_clientdrilldown',
  MY_MONITORING_REPORTS = 'reports_mymonitoring',
  CONTACTS = 'contacts',
  UNAUTHORIZED = 'unauthorized',
  SERVER_OUTAGE = 'server-outage',
  LOGOUT = 'logout',
  NOT_FOUND = 'not-found',
}
