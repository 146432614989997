import { connectionService, httpService, userSettingsService } from '@/services/services-collection';
import { StoreMutation } from '@/store/store-types';
import { IconType } from '@soberlink/soberlink-vue-library';
import { Connection, ConnectionStatus } from '@/services/connection/connection-service-types';
import { HttpResponse } from '@/services/http/https-service-types';
import { extractRelationshipObj, determineUserIdentifier, formatCreateDate } from '@/services/connection/connection-service-utils';
import { Modals, SlideoverType, ToastData } from '@/types';
import type { EventBusKey } from '@vueuse/core';
import { useEventBus } from '@vueuse/core';
import { ref } from 'vue';
import { showErrorToast } from '@/utils/error-handling-utils';

/**
 * An event that is emitted once an invitation has been either accepted or declined.
 * Listeners can then execute a callback related to this action.
 */
export const updateInvitationBusKey: EventBusKey<string> = Symbol('on-update-invitation');

/**
 * An event that is emitted once an invitation has been accepted.
 * Listeners can then execute a callback related to this action.
 */
export const acceptInvitationBusKey: EventBusKey<string> = Symbol('on-accept-invitation');

export const acceptAllBusKey: EventBusKey<string> = Symbol('on-accept-all-invitations');

/**
 * A plugin to contain and control actions and store mutations related to the invitation slideover
 */
export default {
  install: (app, $store) => {
    // Define the required busses used to emit events when invitations are updated
    const updateBus = useEventBus(updateInvitationBusKey);
    const acceptBus = useEventBus(acceptInvitationBusKey);
    const acceptAllBus = useEventBus(acceptAllBusKey);

    // Keeps track of the accept-all checkbox's value in the slideover
    const autoAcceptAllSetting = ref(true);

    /**
     * Triggers the opening of the invitation slideover
     * @param connectionKey - the invitation's connection key
     * @param notificationKey - the notification key, only present
     * when slideover opening is triggered by a notification
     */
    async function openSlideover(connectionKey: string, notificationKey?: string) {
      autoAcceptAllSetting.value = true;

      // Store the connection key in case this method fails, and we have to retry fetching the connection via their key
      $store.commit(StoreMutation.SET_INVITATION_SLIDEOVER_DATA, {
        connectionKey,
      });

      // Fetch the connection from the connection service
      try {
        let connection;
        const connectionRequest = connectionService.constructConnectionByKeyRequest(connectionKey, (response: HttpResponse<Connection>) => (connection = response.data));
        await httpService.sendRequest(connectionRequest, { 500: () => _setLoadError() });

        if (connection) {
          const connectionRelationshipObj = extractRelationshipObj(connection);
          const name = determineUserIdentifier(connectionRelationshipObj);
          const date = formatCreateDate(connection?.createDate);

          $store.commit(StoreMutation.SET_INVITATION_SLIDEOVER_DATA, {
            connectionKey: connection?.connectionKey,
            notificationKey,
            name,
            date,
            loadError: false,
          });

          $store.commit(StoreMutation.OPEN_SLIDEOVER, SlideoverType.INVITATION);
        } else {
          // If the call does not return a connection, show an error
          _setLoadError();
        }
      } catch (e) {
        // If the call fails, show an error
        console.error(`Something went wrong while opening slideover: ${e}`);
        _setLoadError();
      }
    }

    function closeSlideover() {
      $store.commit(StoreMutation.CLOSE_SLIDEOVER, SlideoverType.INVITATION);
      $store.commit(StoreMutation.CLEAR_INVITATION_SLIDEOVER_DATA);
    }

    async function updateUserInvitation(subscriptionStatus: ConnectionStatus, toastPayload: ToastData) {
      const connectionKey = toastPayload.toastKey;
      const updateErrorHandlers = { 500: () => showErrorToast('An error occurred while updating your invitation') };

      try {
        // Update the user status accordingly
        const updateRequest = connectionService.constructConnectionStatusUpdateRequest(connectionKey, subscriptionStatus);
        await httpService.sendRequest(updateRequest, updateErrorHandlers);

        // If the auto-accept boolean has been changed from its previous setting, update the setting in the User Settings service
        const currentAutoAcceptInvites = !!(await userSettingsService.getUserSettings())?.userConnectionSettings?.isAutoAcceptInvitation;
        if (autoAcceptAllSetting.value !== currentAutoAcceptInvites) {
          const updateAutoAcceptRequest = userSettingsService.constructUpdateAutoAcceptInvitesRequest(autoAcceptAllSetting.value);
          await httpService.sendRequest(updateAutoAcceptRequest, updateErrorHandlers);

          // If we choose to accept all, update the Home page to re-fetch the notifications.
          // The re-fetching of the Connections page invitations is already taken care of as part of this method.
          if (autoAcceptAllSetting.value) {
            acceptAllBus.emit();
          }
        }

        // Show a toast to notify the user that the invitation has been updated
        $store.commit(StoreMutation.SHOW_TOAST, toastPayload);

        // Emit an event notifying listeners that an invitation has been updated
        updateBus.emit($store.state.invitationSlideoverData?.notificationKey);

        if (subscriptionStatus === ConnectionStatus.ACTIVE) {
          // Emit an event notifying listeners that an invitation has been updated
          acceptBus.emit();
        }
      } catch (e) {
        console.error(`Something went wrong while updating the invitation: ${e}`);
      } finally {
        closeSlideover();
      }
    }

    async function acceptUser() {
      // Grab the current invitation id from the store
      const toastKey = $store.state.invitationSlideoverData.connectionKey;

      await updateUserInvitation(ConnectionStatus.ACTIVE, {
        toastKey,
        toastText: 'Invitation accepted',
        toastType: IconType.SUCCESS,
        dismissable: false,
      });
    }

    async function declineUser() {
      // Grab the current invitation id from the store
      const toastKey = $store.state.invitationSlideoverData.connectionKey;

      await updateUserInvitation(ConnectionStatus.DECLINED, {
        toastKey,
        toastText: 'Invitation declined',
        toastType: IconType.INFO,
        dismissable: false,
      });

      $store.commit(StoreMutation.CLOSE_MODAL, Modals.DECLINE_INVITE_MODAL);
    }

    function _setLoadError() {
      $store.commit(StoreMutation.SET_SLIDEOVER_LOAD_ERROR, true);
      $store.commit(StoreMutation.OPEN_SLIDEOVER, SlideoverType.INVITATION);
    }

    app.provide('invitationSlideover', {
      openSlideover,
      closeSlideover,
      acceptUser,
      declineUser,
      autoAcceptAllSetting,
    });
  },
};
