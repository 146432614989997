//====================================================================
// Mock Implementation
//====================================================================
import { injectable } from 'inversify';
import { HttpMethod, HttpRequest, HttpResponse } from '@/services/http/https-service-types';
import { IClientService } from '@/services/client/client-service-interface';
import { AppliedClientStatus, Client, ClientStatus } from '@/services/client/client-service-types';

@injectable()
export class MockClientService implements IClientService {
  //--------------------------------------------------
  // Constructor
  //--------------------------------------------------
  constructor() {}

  constructGetClientListRequest(clientStatus: ClientStatus[], responseCallback: (response: HttpResponse<Client[]>) => void): HttpRequest {
    const clients: Client[] = [
      {
        alias: 'Darrell Steward - Device 1',
        clientKey: 'a2a2d9d7-df9d-4a9d-8293-811a1bf1dfbe',
        clientStatus: ClientStatus.ACTIVE,
        appliedClientStatus: AppliedClientStatus.ACTIVE,
        deviceId: '3600AB13',
        planName: 'Family Law Level 2 Premium',
        planStartDate: new Date(),
      },
      {
        alias: 'Darrell Steward - Device 2',
        clientKey: '44d8c5db-db35-4e9d-aa6f-07ae01235f8f',
        clientStatus: ClientStatus.ACTIVE,
        appliedClientStatus: AppliedClientStatus.PENDING,
        deviceId: '3600AB52',
        planName: 'Family Law Level 1 Basic',
        planStartDate: new Date(),
      },
      {
        alias: 'Darrell Steward - Device 3',
        clientKey: '1ff2d90a-035e-44e5-be06-bf09ea322758',
        clientStatus: ClientStatus.ARCHIVED,
        appliedClientStatus: AppliedClientStatus.ARCHIVED,
        deviceId: '3600AB97',
        planName: 'Family Law Level 2 Plus',
        planStartDate: new Date(),
      },
    ];

    return {
      url: '/mock/client',
      method: HttpMethod.GET,
      batchResponseTypingCallback: () => clients,
      responseCallback,
    };
  }

  constructGetClientByKeyRequest(clientKey: string, responseCallback: (response: HttpResponse<Client>) => void): HttpRequest {
    const client = {
      alias: 'Darrell Steward - Device 1',
      clientKey: 'a2a2d9d7-df9d-4a9d-8293-811a1bf1dfba',
      clientStatus: ClientStatus.ACTIVE,
      deviceId: '3600AB13',
      planName: 'Family Law Level 2 Premium',
      planStartDate: new Date(),
    };

    return {
      url: '/mock/client',
      method: HttpMethod.GET,
      batchResponseTypingCallback: () => client,
      responseCallback,
    };
  }
}
