//====================================================================
// Mock Implementation
//====================================================================
import { injectable } from 'inversify';
import { HttpMethod, HttpRequest, HttpResponse } from '@/services/http/https-service-types';
import { Device, DeviceRegistrationStatus } from '@/services/device/device-service-types';
import { IDeviceService } from '@/services/device/device-service-interface';

@injectable()
export class MockDeviceService implements IDeviceService {
  //--------------------------------------------------
  // Constructor
  //--------------------------------------------------
  constructor() {}

  //--------------------------------------------------------------
  // constructGetDeviceInfoRequest
  //--------------------------------------------------------------
  constructGetDeviceInfoRequest(clientKey: string, responseCallback: (response: HttpResponse<Device>) => void): HttpRequest {
    const deviceKey = `3600AB${Math.floor(Math.random() * (99 - 10 + 1)) + 10}`;

    const deviceRegistrationStatus = Math.random() < 0.5 ? DeviceRegistrationStatus.PENDING : DeviceRegistrationStatus.ACTIVE;

    const device = {
      deviceKey,
      atriumProduct: {
        productId: 'abc123',
        productDisplayName: 'Soberlink Cellular',
      },
      deviceRegistrationStatus,
      activeSinceDate: new Date('9/15/23'),
      testsUntilCalibration: 53,
    };

    return {
      url: `/mock/client/${clientKey}/device`,
      method: HttpMethod.GET,
      batchResponseTypingCallback: () => device,
      responseCallback,
    };
  }

  //--------------------------------------------------------------
  // constructValidateDeviceRequest
  //--------------------------------------------------------------
  constructValidateDeviceRequest(clientKey: string, deviceID: string, securityPin: string, responseCallback: (response: HttpResponse<void>) => void): HttpRequest {
    if (!deviceID || !securityPin) {
      throw new Error('The client key, device ID and security pin must all be provided in order to validate device.');
    }

    const url = `/mock/v1/clients/${clientKey}/${deviceID}/${securityPin}`;

    return {
      url,
      method: HttpMethod.GET,
      batchResponseTypingCallback: () => {},
      responseCallback,
    };
  }

  //--------------------------------------------------------------
  // constructActivateDeviceRequest
  //--------------------------------------------------------------
  constructActivateDeviceRequest(clientKey: string, deviceKey: string, devicePin: string, responseCallback: (response: HttpResponse<void>) => void): HttpRequest {
    const url = `/mock/client/${clientKey}/device/${deviceKey}/${devicePin}`;

    return {
      url,
      method: HttpMethod.PUT,
      batchResponseTypingCallback: () => undefined,
      responseCallback,
    };
  }

  //--------------------------------------------------------------
  // constructUpdateDeviceRequest
  //--------------------------------------------------------------
  constructUpdateDeviceRequest(clientKey: string, deviceKey: string, devicePin: string, responseCallback: (response: HttpResponse<void>) => void): HttpRequest {
    const url = `/mock/client/${clientKey}/device/${deviceKey}/${devicePin}`;

    return {
      url,
      method: HttpMethod.POST,
      batchResponseTypingCallback: () => undefined,
      responseCallback,
    };
  }
}
