import store from '@/store';
import { computed } from 'vue';
import { AppView } from '@/store/store-types';

export function useMyMonitoring() {
  /**
   * Whether the current page is a MyMonitoring page
   */
  const isMyMonitoringPage = computed(() => {
    return (store.state.appView as AppView) === AppView.MY_MONITORING;
  });

  return { isMyMonitoringPage };
}
