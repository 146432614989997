export interface Device {
  deviceKey: string;
  atriumProduct: {
    productId: string;
    productDisplayName: string;
  };
  deviceRegistrationStatus: DeviceRegistrationStatus;
  activeSinceDate: Date;
  testsUntilCalibration: number;
}

export enum DeviceRegistrationStatus {
  ACTIVE = 0,
  PENDING = 1,
  DELETED = 2,
  UNKNOWN = 3,
  EXPIRED = 4,
}

export const TYPES = {
  IDeviceService: Symbol.for('IDeviceService'),
};
