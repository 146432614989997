import { Container } from 'inversify';

// -------------------------------------------------
// Import: Config Service
// -------------------------------------------------
// interface
import type { IConfigService } from '@/services/config/config-service-interface';
import { TYPES as configServiceTYPES } from '@/services/config/config-service-types';

// service
import { ConfigService } from '@/services/config/config-service';
import { MockConfigService } from '@/services/config/config-service-mock';

// -------------------------------------------------
// Import: User Settings Service
// -------------------------------------------------
// interface
import type { IUserSettingsService } from '@/services/user-settings/user-settings-service-interface';
import { TYPES as userSettingsServiceTYPES } from '@/services/user-settings/user-settings-service-types';

// service
import { UserSettingsService } from '@/services/user-settings/user-settings-service';
import { MockUserSettingsService } from '@/services/user-settings/user-settings-service-mock';

// -------------------------------------------------
// Import: Batch Service
// -------------------------------------------------
// interface
import type { IBatchService } from '@/services/batch/batch-service-interface';
import { TYPES as batchServiceTYPES } from '@/services/batch/batch-service-interface';

// service
import { BatchService } from '@/services/batch/batch-service';
import { MockBatchService } from '@/services/batch/batch-service-mock';

// -------------------------------------------------
// Import: Authentication Service
// -------------------------------------------------
import type { IAuthService } from '@/services/auth/auth-service-interface';
import { TYPES as authServiceTYPES } from '@/services/auth/auth-service-types';
import { AuthService } from '@/services/auth/auth-service';
import { MockAuthService } from '@/services/auth/auth-service-mock';

// -------------------------------------------------
// Import: Connection Service
// -------------------------------------------------
import { IConnectionService, TYPES as connectionServiceTYPES } from '@/services/connection/connection-service-interface';

import { ConnectionService } from '@/services/connection/connection-service';
import { MockConnectionService } from '@/services/connection/connection-service-mock';

// -------------------------------------------------
// Import: User Service
// -------------------------------------------------
import type { IUserService } from '@/services/user/user-service-interface';
import { TYPES as userServiceTYPES } from '@/services/user/user-service-types';
import { UserService } from '@/services/user/user-service';
import { MockUserService } from '@/services/user/user-service-mock';

// -------------------------------------------------
// Import: Notification Service
// -------------------------------------------------
import { INotificationService, TYPES as notificationServiceTYPES } from '@/services/notification/notification-service-interface';

import { NotificationService } from '@/services/notification/notification-service';
import { MockNotificationService } from '@/services/notification/notification-service-mock';

// -------------------------------------------------
// Import: HTTP Service
// -------------------------------------------------
import type { IHttpService } from '@/services/http/http-service-interface';
import { TYPES as httpServiceTYPES } from '@/services/http/https-service-types';
import { HttpService } from '@/services/http/http-service';

// -------------------------------------------------
// Import: Activity Service
// -------------------------------------------------
import type { IActivityService } from '@/services/activity/activity-service-interface';
import { TYPES as activityServiceTYPES } from '@/services/activity/activity-service-types';
import { ActivityService } from '@/services/activity/activity-service';
import { ActivityServiceMock } from '@/services/activity/activity-service-mock';

// -------------------------------------------------
// Import: PageableUtility Service
// -------------------------------------------------
import type { IPageableUtilityService } from '@/services/utility/pageable-service-interface';
import { TYPES as pageableUtilityServiceTYPES } from '@/services/utility/pageable-service-types';
import { PageableUtilityService } from '@/services/utility/pageable-service';
import { MockHttpService } from '@/services/http/http-service-mock';

// -------------------------------------------------
// Import: Client Service
// -------------------------------------------------
import { IClientService } from '@/services/client/client-service-interface';
import { TYPES as clientServiceTYPES } from '@/services/client/client-service-types';
import { MockClientService } from '@/services/client/client-service-mock';
import { ClientService } from '@/services/client/client-service';

// -------------------------------------------------
// Import: Device Service
// -------------------------------------------------
import { IDeviceService } from '@/services/device/device-service-interface';
import { TYPES as deviceServiceTYPES } from '@/services/device/device-service-types';
import { MockDeviceService } from '@/services/device/device-service-mock';
import { DeviceService } from '@/services/device/device-service';

// -------------------------------------------------
// Import: Media Service
// -------------------------------------------------
import { IMediaService } from '@/services/media/media-service-interface';
import { TYPES as mediaServiceTYPES } from '@/services/media/media-service-types';
import { MockMediaService } from '@/services/media/media-service-mock';
import { MediaService } from '@/services/media/media-service';

// -------------------------------------------------
// Import: Media Service
// -------------------------------------------------
import { MockReportService } from '@/services/report/report-service-mock';
import { TYPES as reportServiceTYPES } from '@/services/report/report-service-types';
import { ReportService } from '@/services/report/report-service';
import { IReportService } from '@/services/report/report-service-interface';

// -------------------------------------------------
// Container: Init
// -------------------------------------------------

const isLocalEnv = process.env.VUE_APP_RUN_MODE === 'local';
const ServicesContainer = new Container();

// -------------------------------------------------
// Inject: Config Service
// -------------------------------------------------

if (isLocalEnv) {
  ServicesContainer.bind<IConfigService>(configServiceTYPES.IConfigService).to(MockConfigService).inSingletonScope();
} else {
  ServicesContainer.bind<IConfigService>(configServiceTYPES.IConfigService).to(ConfigService).inSingletonScope();
}

// -------------------------------------------------
// Inject: User Settings Service
// -------------------------------------------------

if (isLocalEnv) {
  ServicesContainer.bind<IUserSettingsService>(userSettingsServiceTYPES.IUserSettingsService).to(MockUserSettingsService).inSingletonScope();
} else {
  ServicesContainer.bind<IUserSettingsService>(userSettingsServiceTYPES.IUserSettingsService).to(UserSettingsService).inSingletonScope();
}

// -------------------------------------------------
// Inject: Authentication Service
// -------------------------------------------------

if (isLocalEnv) {
  ServicesContainer.bind<IAuthService>(authServiceTYPES.IAuthService).to(MockAuthService).inSingletonScope();
} else {
  ServicesContainer.bind<IAuthService>(authServiceTYPES.IAuthService).to(AuthService).inSingletonScope();
}

// -------------------------------------------------
// Inject: Connection Service
// -------------------------------------------------

if (isLocalEnv) {
  ServicesContainer.bind<IConnectionService>(connectionServiceTYPES.IConnectionService).to(MockConnectionService).inSingletonScope();
} else {
  ServicesContainer.bind<IConnectionService>(connectionServiceTYPES.IConnectionService).to(ConnectionService).inSingletonScope();
}

// -------------------------------------------------
// Inject: Client Service
// -------------------------------------------------

if (isLocalEnv) {
  ServicesContainer.bind<IClientService>(clientServiceTYPES.IClientService).to(MockClientService).inSingletonScope();
} else {
  ServicesContainer.bind<IClientService>(clientServiceTYPES.IClientService).to(ClientService).inSingletonScope();
}

// -------------------------------------------------
// Inject: User Service
// -------------------------------------------------

if (isLocalEnv) {
  ServicesContainer.bind<IUserService>(userServiceTYPES.IUserService).to(MockUserService).inSingletonScope();
} else {
  ServicesContainer.bind<IUserService>(userServiceTYPES.IUserService).to(UserService).inSingletonScope();
}

// -------------------------------------------------
// Inject: Device Service
// -------------------------------------------------

if (isLocalEnv) {
  ServicesContainer.bind<IDeviceService>(deviceServiceTYPES.IDeviceService).to(MockDeviceService).inSingletonScope();
} else {
  ServicesContainer.bind<IDeviceService>(deviceServiceTYPES.IDeviceService).to(DeviceService).inSingletonScope();
}

// -------------------------------------------------
// Inject: Media Service
// -------------------------------------------------

if (isLocalEnv) {
  ServicesContainer.bind<IMediaService>(mediaServiceTYPES.IMediaService).to(MockMediaService).inSingletonScope();
} else {
  ServicesContainer.bind<IMediaService>(mediaServiceTYPES.IMediaService).to(MediaService).inSingletonScope();
}

// -------------------------------------------------
// Inject: Notification Service
// -------------------------------------------------

if (isLocalEnv) {
  ServicesContainer.bind<INotificationService>(notificationServiceTYPES.INotificationService).to(MockNotificationService).inSingletonScope();
} else {
  ServicesContainer.bind<INotificationService>(notificationServiceTYPES.INotificationService).to(NotificationService).inSingletonScope();
}

// -------------------------------------------------
// Inject: Batch service
// -------------------------------------------------

if (isLocalEnv) {
  ServicesContainer.bind<IBatchService>(batchServiceTYPES.IBatchService).to(MockBatchService).inSingletonScope();
} else {
  ServicesContainer.bind<IBatchService>(batchServiceTYPES.IBatchService).to(BatchService).inSingletonScope();
}

// -------------------------------------------------
// Inject: Http Service
// -------------------------------------------------

if (isLocalEnv) {
  ServicesContainer.bind<IHttpService>(httpServiceTYPES.IHttpService).to(MockHttpService).inSingletonScope();
} else {
  ServicesContainer.bind<IHttpService>(httpServiceTYPES.IHttpService).to(HttpService).inSingletonScope();
}

// -------------------------------------------------
// Inject: Activity Service
// -------------------------------------------------

if (isLocalEnv) {
  ServicesContainer.bind<IActivityService>(activityServiceTYPES.IActivityService).to(ActivityServiceMock).inSingletonScope();
} else {
  ServicesContainer.bind<IActivityService>(activityServiceTYPES.IActivityService).to(ActivityService).inSingletonScope();
}

// -------------------------------------------------
// Inject: Report Service
// -------------------------------------------------

if (isLocalEnv) {
  ServicesContainer.bind<IReportService>(reportServiceTYPES.IReportService).to(MockReportService).inSingletonScope();
} else {
  ServicesContainer.bind<IReportService>(reportServiceTYPES.IReportService).to(ReportService).inSingletonScope();
}

// -------------------------------------------------
// Inject: IPageableUtility Service
// -------------------------------------------------

// no mock service for utility
if (isLocalEnv) {
  ServicesContainer.bind<IPageableUtilityService>(pageableUtilityServiceTYPES.IPageableUtilityService).to(PageableUtilityService).inSingletonScope();
} else {
  ServicesContainer.bind<IPageableUtilityService>(pageableUtilityServiceTYPES.IPageableUtilityService).to(PageableUtilityService).inSingletonScope();
}

export { ServicesContainer };
