import { inject, injectable } from 'inversify';

//-----------------------------------------------
// Http Service
//-----------------------------------------------
import type { IHttpService } from '@/services/http/http-service-interface';
import { ErrorHandlers, HttpRequest, HttpResponse, TYPES as httpServiceTYPES } from '@/services/http/https-service-types';

//-----------------------------------------------
// Wrapper service
//-----------------------------------------------
import { IBatchService } from '@/services/batch/batch-service-interface';
import { BatchWrapper, SingleBatchResponse } from '@/services/batch/batch-service-types';

//====================================================================
// Mock Implementation
//====================================================================

@injectable()
export class MockBatchService implements IBatchService {
  //-----------------------------------------------
  // Fields
  //-----------------------------------------------

  private _httpService: IHttpService;

  //--------------------------------------------------
  // Constructor
  //--------------------------------------------------

  constructor(@inject(httpServiceTYPES.IHttpService) httpService: IHttpService) {
    this._httpService = httpService;
  }

  //---------------------------------------
  // sendBatchRequest
  //---------------------------------------
  async sendBatchRequest(requests: HttpRequest[], customErrorHandlers?: ErrorHandlers): Promise<void> {
    for (let i = 0; i < requests.length; i++) {
      const curRequest: HttpRequest = requests[i];

      let res;
      await this._httpService.sendRequest(
        {
          ...curRequest,
          batchResponseTypingCallback: undefined,
          responseCallback: (response: HttpResponse<BatchWrapper>) => (res = response),
        },
        customErrorHandlers
      );

      let responseBody = res?.body;

      if (curRequest.batchResponseTypingCallback) {
        responseBody = curRequest.batchResponseTypingCallback(res[i]);
      }

      if (curRequest.responseCallback) {
        curRequest.responseCallback({ data: responseBody, headers: {}, status: 200 });
      }
    }
  }
}
