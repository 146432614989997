// ----------------------------------------------------------------
// Interface
// ----------------------------------------------------------------

export interface IConfigService {
  // -----------------------
  // Methods
  // -----------------------
  init: () => Promise<void>;
  getExternalLinks: () => Promise<ExternalLink[]>;
  getConfig: () => Promise<Config>;
}

// ----------------------------------------------------------------
// Settings
// ----------------------------------------------------------------

export class Config {
  // -----------------------
  // Constructor
  // -----------------------

  public Config: LinkSettings;

  // ------------------------
  // Properties
  // ------------------------
  public SessionInactivityThresholdSec: number;
  public InactivityWarningPopupSec: number;
  public InactivityWarningLogoutPaddingSec: number;
  public EnvironmentName: string;

  constructor() {
    this.Config = new LinkSettings();
    this.SessionInactivityThresholdSec = 0;
    this.InactivityWarningPopupSec = 0;
    this.InactivityWarningLogoutPaddingSec = 0;
    this.EnvironmentName = '';
  }
}

// ----------------------------------------------------------------
// Link settings
// ----------------------------------------------------------------

export class LinkSettings {
  public ExternalLinks: ExternalLink[];

  constructor() {
    this.ExternalLinks = [];
  }
}

// ---------------
// ExternalLinks
// ---------------
export interface ExternalLink {
  Name: ExternalLinkName;
  Url: string;
}

export enum ExternalLinkName {
  ACCOUNT_SETTINGS = 'Settings',
  BILLING = 'Billing',
  SUPPORT = 'Support',
}
