import { computed, ComputedRef } from 'vue';
import { AppView } from '@/store/store-types';
import store from '@/store';
import { useRoute } from 'vue-router';
import { Client, ClientStatus } from '@/services/client/client-service-types';

/**
 * A drilldown module to house repeated connection-related logic
 */
export function useDrilldown() {
  const $route = useRoute();

  // Whether the page is the drilldown of one of the logged-in user's connections
  const isConnectionDrilldown = computed(() => {
    return store.state.appView === AppView.CLIENT_DRILLDOWN;
  });

  // Whether the current page is the currently logged-in user's own drilldown page or other top-level page
  const isOwnPage = computed(() => {
    return store.state.appView === AppView.MY_MONITORING;
  });

  const selectedDrilldownClient: ComputedRef<Client | undefined> = computed(() => {
    return store.state.selectedDrilldownClient;
  });

  const normalizedDrilldownPathName = computed(() => {
    return ($route.name as string)?.split('_')[0]?.replace('/', '');
  });

  // The connection name of the current drilldown connection, if present
  const drilldownClientName = computed<string>(() => {
    const drilldownClient = selectedDrilldownClient;

    if (isConnectionDrilldown.value && drilldownClient) {
      return drilldownClient.value?.alias;
    }
    return undefined;
  });

  // The connection name of the current drilldown connection, if present
  const drilldownClientStatus = computed<ClientStatus>(() => {
    const drilldownClient = selectedDrilldownClient.value;

    if (isConnectionDrilldown.value && drilldownClient) {
      return drilldownClient?.clientStatus;
    }
    return undefined;
  });

  // The client key of the current drilldown client, if present
  const drilldownClientKey = computed<string>(() => {
    const isDrilldownView = store.state.appView === AppView.CLIENT_DRILLDOWN;
    const drilldownConnectionKey = selectedDrilldownClient.value?.clientKey;

    return isDrilldownView && drilldownConnectionKey ? drilldownConnectionKey : undefined;
  });

  return {
    drilldownClientName,
    drilldownClientKey,
    drilldownClientStatus,
    isConnectionDrilldown,
    isOwnPage,
    normalizedDrilldownPathName,
  };
}
