// @ts-ignore
import connectImage from '@/assets/images/connect-device.png';
// @ts-ignore
import reportsImage from '@/assets/images/report.png';
// @ts-ignore
import connectionsImage from '@/assets/images/connections-page.png';
import { useRouter } from 'vue-router';
import { RouteName } from '@/router/router-types';

export interface DirectionWidgetData {
  heading: string;
  subtext: string;
  buttonText: string;
  imageUrl: string;
  imageAlt: string;
  widgetClasses: string;
  buttonCallback: () => void;
}

export function useDirectionWidget() {
  const $router = useRouter();

  const clientHomePageWidgets: DirectionWidgetData[] = [
    {
      heading: 'View your reports',
      subtext: 'Quickly access your test data, whenever you need it.',
      buttonText: 'View reports',
      imageUrl: reportsImage,
      imageAlt: 'Example Client Detail report',
      widgetClasses: 'direction-widget__reports',
      buttonCallback: () => $router.push({ path: '/my-monitoring/reports' }),
    },
    {
      heading: 'Activate your replacement device',
      subtext: 'Swap your current device for a replacement in just a few clicks.',
      buttonText: 'Activate device',
      imageUrl: connectImage,
      imageAlt: 'Soberlink Connect device',
      widgetClasses: 'direction-widget__device-activation',
      buttonCallback: () => $router.push({ path: '/my-monitoring/device' }),
    },
  ];

  const contactHomePageWidgets: DirectionWidgetData[] = [
    {
      heading: 'View your connections',
      subtext: 'Access the list of your Monitored Clients and drill in to view their reports.',
      buttonText: 'View connections',
      imageUrl: connectionsImage,
      imageAlt: 'Soberlink connections',
      widgetClasses: 'direction-widget__reports',
      buttonCallback: () => $router.push(RouteName.CONNECTIONS),
    },
  ];

  return { clientHomePageWidgets, contactHomePageWidgets };
}
