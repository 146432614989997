import { injectable } from 'inversify';
import { HttpMethod, HttpRequest, HttpResponse } from '@/services/http/https-service-types';
import { SingleBatchResponse } from '@/services/batch/batch-service-types';
import { Client, ClientStatus } from '@/services/client/client-service-types';
import { IClientService } from '@/services/client/client-service-interface';

@injectable()
export class ClientService implements IClientService {
  //--------------------------------------------------
  // Constructor
  //--------------------------------------------------
  constructor() {}

  //--------------------------------------------------------------
  // constructGetClientListRequest
  //--------------------------------------------------------------
  constructGetClientListRequest(clientStatus: ClientStatus[], responseCallback: (response: HttpResponse<Client[]>) => void): HttpRequest {
    const url = '/api/v1/clients';

    return {
      url,
      method: HttpMethod.GET,
      batchResponseTypingCallback: (response: SingleBatchResponse<Client[]>) => response.body as Client[],
      responseCallback,
    };
  }

  //--------------------------------------------------------------
  // constructGetClientByKeyRequest
  //--------------------------------------------------------------
  constructGetClientByKeyRequest(clientKey: string, responseCallback: (response: HttpResponse<Client>) => void) {
    if (!clientKey) {
      throw new Error('A client key must be provided in order to fetch the client.');
    }

    const url = `/api/v1/clients/${clientKey}`;

    return {
      url,
      method: HttpMethod.GET,
      batchResponseTypingCallback: (response: SingleBatchResponse<Client>) => response.body as Client,
      responseCallback,
    };
  }
}
