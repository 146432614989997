import { inject, injectable } from 'inversify';
import {
  BannerNotification,
  BannerNotificationType,
  BaseNotification,
  ConnectionInvitationNotification,
  NotificationCategory,
  NotificationType,
} from '@/services/notification/notification-service-types';

//-----------------------------------------------
// Http Service
//-----------------------------------------------
import type { IHttpService } from '@/services/http/http-service-interface';
import { HttpMethod, HttpResponse, TYPES as httpServiceTYPES } from '@/services/http/https-service-types';

//-----------------------------------------------
// Activity Service
//-----------------------------------------------
import type { IActivityService } from '@/services/activity/activity-service-interface';
import { TYPES as activityServiceTYPES } from '@/services/activity/activity-service-types';
//-----------------------------------------------
// Wrapper service
//-----------------------------------------------
import { INotificationService } from '@/services/notification/notification-service-interface';

//====================================================================
// Mock Implementation
//====================================================================
@injectable()
export class MockNotificationService implements INotificationService {
  //-----------------------------------------------
  // Fields
  //-----------------------------------------------

  private _httpService: IHttpService;
  private _activityService: IActivityService;
  notifications: BaseNotification[]; // Set an internal notifications array so that we can maintain state while mocking

  //--------------------------------------------------
  // Constructor
  //--------------------------------------------------

  constructor(@inject(httpServiceTYPES.IHttpService) httpService: IHttpService, @inject(activityServiceTYPES.IActivityService) activityService: IActivityService) {
    this._httpService = httpService;
    this._activityService = activityService;
    this.notifications = getInitialNotifications();
  }

  constructGetNotificationRequest(category: NotificationCategory, filterTypes: NotificationType[], responseCallback: (response: HttpResponse<BaseNotification[]>) => void) {
    let notifications;

    // Handle category selection
    if (category === NotificationCategory.BANNER) {
      notifications = getBannerNotifications();
    } else if (category === NotificationCategory.CONNECTION_INVITE) {
      notifications = getInviteNotifications();
    } else {
      notifications = this.notifications;
    }

    // Handle filtering
    if (filterTypes) {
      notifications = notifications.filter((notification: BaseNotification) => filterTypes.includes(notification.type));
    }

    return {
      url: '/mock/Notifications',
      method: HttpMethod.GET,
      batchResponseTypingCallback: () => notifications,
      responseCallback,
    };
  }
}

// Used to load the notifications upon the initial load
function getInitialNotifications() {
  return [...getInviteNotifications(), ...getBannerNotifications()];
}

function getInviteNotifications() {
  const invites: ConnectionInvitationNotification[] = [];

  for (let i = 0; i < 10; i++) {
    const ConnectionKey = `000-000-0-${i}-type-0`; // Randomise the connection type

    const currentNotification: ConnectionInvitationNotification = {
      title: 'You have been invited to be a contact',
      description: `You have been invited to receive alerts and notifications for Person ${i + 1}`,
      category: NotificationCategory.CONNECTION_INVITE,
      type: NotificationType.CONNECTION_INVITE,
      connectionKey: ConnectionKey,
      notificationKey: `${ConnectionKey}-key-${i}`, // The notification key will be the same as the connectionKey, but with a suffix
    };

    // invites.push(currentNotification);
  }

  return invites;
}

function getBannerNotifications(): BannerNotification[] {
  return [
    // Billing information
    /*{
                                                                              title: 'Missing Billing Information: Update your payment details to ensure uninterrupted service.',
                                                                              type: NotificationType.NO_VALID_PAYMENT_METHOD,
                                                                              category: NotificationCategory.BANNER,
                                                                              notificationKey: `mock-no-valid-payment`,
                                                                              bannerType: BannerNotificationType.WARNING,
                                                                            },*/
    // Change device
    {
      title:
        'Device Replacement Required: <a href="https://www.soberlink.com/recalibration-request" target="_blank">Submit a Recalibration Request</a> to avoid suspension, or activate your replacement Device. 53 tests remaining.',
      type: NotificationType.CHANGE_DEVICE,
      category: NotificationCategory.BANNER,
      notificationKey: `mock-update-device`,
      bannerType: BannerNotificationType.WARNING,
      buttonUrl: '/my-monitoring/a2a2d9d7-df9d-4a9d-8293-811a1bf1dfbe/device',
      buttonText: 'Activate Device',
    },
    {
      title:
        'Device Replacement Required: <a href="https://www.soberlink.com/recalibration-request" target="_blank">Submit a Recalibration Request</a> to avoid suspension, or activate your replacement Device. 22 tests remaining.',
      type: NotificationType.CHANGE_DEVICE,
      category: NotificationCategory.BANNER,
      notificationKey: `mock-update-device`,
      bannerType: BannerNotificationType.ERROR,
      buttonUrl: '/my-monitoring/44d8c5db-db35-4e9d-aa6f-07ae01235f8f/device',
      buttonText: 'Activate Device',
    },
  ];
}
