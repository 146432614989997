import { IconType } from '@soberlink/soberlink-vue-library';

// --------------------------------------------------
// Modals
// --------------------------------------------------
export type ActivityModal = {
  timerIntervalId: number;
  content: string;
};

export enum Modals {
  ACCEPT_ALL_INVITES_MODAL = 'accept-all-modal',
  ACTIVITY_MODAL = 'activity-modal',
  DECLINE_INVITE_MODAL = 'decline-invite-modal',
  REPORTS_MODAL = 'reports-modal',
}

// -------------------------------------------------
// Slideover
// -------------------------------------------------
export enum SlideoverType {
  INVITATION = 'invitation',
  CHANGE_DEVICE = 'change-device',
}

export type InvitationSlideoverData = {
  date?: string;
  connectionKey?: string;
  notificationKey?: string; // Only present if the invitation is opened from a home-page notification
  name?: string;
  loadError?: boolean;
};

// -------------------------------------------------
// Toast
// -------------------------------------------------
export interface ToastData {
  toastKey: string;
  toastText: string;
  toastType: IconType;
  dismissable?: boolean;
  buttonText?: string;
  onClickButton?: () => void;
}
