import { BaseRelationship, ClientRelationship, Connection, ConnectionGroupByType, ConnectionRelationshipType, ConnectionStatus } from '@/services/connection/connection-service-types';
import moment from 'moment';
import { ClientStatus } from '@/services/client/client-service-types';

const invalidDateString = 'Invalid date';

export function formatCreateDate(createDate: Date): string | undefined {
  const formattedDate = moment(createDate).format('MM/DD/YY');
  return formattedDate !== invalidDateString ? formattedDate : undefined;
}

export function generateConnectionQueryString(
  connectionRelationshipType: ConnectionRelationshipType | undefined,
  connectionStatus: ConnectionStatus | undefined,
  clientStatus: ClientStatus[] | undefined,
  groupBy?: ConnectionGroupByType | undefined,
  clientKey?: string | undefined
) {
  let queryString = '';

  if (clientKey) {
    queryString += `&clientKey=${clientKey}`;
  }

  if (groupBy) {
    queryString += `&groupBy=${groupBy}`;
  }

  if (connectionStatus) {
    queryString += `&status=${connectionStatus}`;
  }

  if (clientStatus) {
    const connectionClientStatus = clientStatus?.join(',');
    queryString += `&clientStatus=${connectionClientStatus}`;
  }

  if (connectionRelationshipType) {
    queryString += `&relationship=${connectionRelationshipType}`;
  }

  // Remove the leading `&`
  if (queryString[0] === '&') {
    queryString = queryString.substring(1, queryString.length);
  }

  return queryString;
}

export const relationshipMap = {
  0: 'undefined',
  1: 'client',
  2: 'concernedParty',
  3: 'contact',
  4: 'self',
};

export const extractRelationshipObj = (connection: Connection) => {
  const relationshipType: ConnectionRelationshipType | undefined = connection?.relationship?.relationshipType;

  return connection?.relationship[`${relationshipMap[relationshipType]}`];
};

/**
 * A util to extract the best way of identifying a user.
 * As the user identifier, prioritize showing the user's full name.
 * If this is not available, show the device alias.
 * If this is not available, show 'Unknown'
 */
export const determineUserIdentifier = (relationship: BaseRelationship) => {
  const name = `${relationship?.firstName || ''} ${relationship?.lastName || ''}`;
  return name?.trim() || (relationship as ClientRelationship)?.alias || 'Unknown';
};
