// ----------------------------------------------------------------
// Connections
// ----------------------------------------------------------------
import { ClientStatus } from '@/services/client/client-service-types';

export interface Connection {
  connectionKey: string;
  userKey: string;
  status: ConnectionStatus;
  createDate: Date;
  relationship: ConnectionRelationship;
}

export interface ClientRelationship extends BaseRelationship {
  clientKey: string;
  deviceId: string;
  alias: string;
  clientStatus: ClientStatus;
}

export interface BaseRelationship {
  userKey: string;
  firstName: string;
  lastName: string;
  timeZoneName: string;
  phoneNumber: {
    number: string;
    region: {
      countryCode: number;
    };
  };
}

export interface ConnectionRelationship {
  relationshipType: ConnectionRelationshipType;
  client?: ClientRelationship;
  contact?: BaseRelationship;
  concernedParty?: BaseRelationship;
  self?: BaseRelationship;
}

// -------------------------------------  ---------------------------
// Connection groups
// ----------------------------------------------------------------
export interface ConnectionGroup {
  group: ClientRelationship;
  items: Connection[];
}

// ----------------------------------------------------------------
// Connection enums
// ----------------------------------------------------------------
export enum ConnectionStatus {
  UNKNOWN = 0,
  ACTIVE = 1,
  DECLINED = 2,
  PENDING_ACCEPTANCE = 3,
}

export enum ConnectionRelationshipType {
  UNDEFINED = 0,
  CLIENT = 1,
  CONCERNED_PARTY = 2,
  CONTACT = 3,
  SELF = 4,
}

export enum ConnectionGroupByType {
  UNDEFINED = 0,
  CLIENT = 1,
}
