//====================================================================
// Mock Implementation
//====================================================================
import { injectable } from 'inversify';
import { HttpMethod, HttpResponse } from '@/services/http/https-service-types';
import { IMediaService } from '@/services/media/media-service-interface';
import { MediaType, VideoPreviewData } from '@/services/media/media-service-types';

@injectable()
export class MockMediaService implements IMediaService {
  //--------------------------------------------------
  // Constructor
  //--------------------------------------------------
  constructor() {}

  constructGetDeviceMediaRequest(deviceKey: string, responseCallback: (response: HttpResponse<VideoPreviewData[]>) => void) {
    const videoPreviews: VideoPreviewData[] = [
      {
        mediaKey: 'abc123',
        mediaType: MediaType.VIDEO_PREVIEW,
        imageUrl: 'https://i.ibb.co/4Z0HLwG/nature.jpg',
        url: 'https://www.google.com',
        title: 'Taking your first test',
        description: 'Watch this video to learn all of the tips and tricks for taking your first monitoring test.',
      },
      {
        mediaKey: 'def456',
        mediaType: MediaType.VIDEO_PREVIEW,
        imageUrl: 'https://i.ibb.co/wy5W4zz/sun.jpg',
        url: 'https://www.google.com',
        title: 'Reporting & Alerts',
        description: 'Watch this video to see an overview of how reporting and alerts work.',
      },
    ];

    return {
      url: `/mock/media/device/${deviceKey}`,
      method: HttpMethod.GET,
      batchResponseTypingCallback: () => videoPreviews,
      responseCallback,
    };
  }
}
