import { injectable } from 'inversify';
import axios from 'axios';
import type { IHttpService } from '@/services/http/http-service-interface';
import { ErrorHandlers, HttpMethod, HttpRequest, HttpResponse } from '@/services/http/https-service-types';
import { handleHttpError } from '@/utils/error-handling-utils';

@injectable()
export class HttpService implements IHttpService {
  private _shouldHandleOnError = true;
  private _headers = {
    headers: {
      'x-csrf': '1',
    },
  };

  constructor() {
    axios.defaults.headers.get['x-csrf'] = '1';
    axios.defaults.headers.post['x-csrf'] = '1';
    axios.defaults.headers.put['x-csrf'] = '1';
  }

  async sendRequest(request: HttpRequest, customErrorHandlers?: ErrorHandlers): Promise<void> {
    const { method, headers, shouldHandleOnError, url, body } = this.formatRequest(request);

    try {
      let res: HttpResponse<any>;
      switch (method) {
        case HttpMethod.GET:
          res = await axios.get(url, headers);
          break;
        case HttpMethod.POST:
          res = await axios.post(url, body, headers);
          break;
        case HttpMethod.PUT:
          res = await axios.put(url, headers);
          break;
        default:
          console.debug('Failed to execute request. No matching http method found.');
          break;
      }

      // If there's an assignment callback, execute it (used to assign responses to variables)
      // Note that a response is not returned from this request – the only way to access the response is via this callback
      if (request.responseCallback) {
        request.responseCallback(res);
      }

      if (res.status === 204 && res.headers?.location) {
        window.location.replace(res.headers.location);
        return;
      }
    } catch (error) {
      if (shouldHandleOnError) {
        handleHttpError(error, customErrorHandlers);
      }
    }
  }

  formatRequest(request: HttpRequest): HttpRequest {
    const { url } = request;
    let { headers, shouldHandleOnError } = request;

    if (!headers) {
      headers = this._headers;
    }

    if (shouldHandleOnError === null || shouldHandleOnError === undefined) {
      shouldHandleOnError = this._shouldHandleOnError;
    }

    return {
      url,
      headers,
      shouldHandleOnError,
      ...request,
    };
  }

  //----------------------
  // END
  //----------------------
}
