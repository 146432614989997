import { ActivityModal, InvitationSlideoverData, ToastData } from '@/types';
import { BannerNotification } from '@/services/notification/notification-service-types';
import { ExternalLink } from '@/services/config/config-service-interface';
import { Client } from '@/services/client/client-service-types';

export interface State {
  appView: AppView;
  environment: EnvironmentVar;
  toasts: ToastData[];
  activityModal: ActivityModal;
  invitationSlideoverData: InvitationSlideoverData;
  banners: BannerNotification[];
  openModals: Set<string>;
  openSlideovers: Set<string>;
  selectedDrilldownClient: Client | undefined;
  invitationCount: number | undefined; // The number of pending invitations for the logged-in user
  isLoading: boolean;
  externalLinks: ExternalLink[];
  myMonitoring: {
    clientList: Client[];
    selectedClientKey: string;
  };
  // Determines which permissions a logged-in user has, and which UI components are available to them
  permissions: {
    topLevelConnections: boolean;
    myMonitoring: boolean;
  };
  isAppReady: boolean;
}

export enum AppView {
  TOP_LEVEL = 'top-level',
  MY_MONITORING = 'my-monitoring',
  CLIENT_DRILLDOWN = 'client-drilldown',
}

export enum EnvironmentVar {
  LOCAL = 'local',
  DEV = 'development',
  STAGE = 'stage',
  PROD = 'production',
}

export enum StoreAction {
  INITIALIZE = 'initialize',
  BANNERS = 'banners',
  FETCH_PENDING_INVITATION_COUNT = 'fetchPendingInvitationCount',
  PREPARE_CLIENT_LIST = 'prepareClientList',
  GET_ENV_VAR = 'getEnvironmentVar',
  GET_EXTERNAL_LINKS = 'getExternalLinks',
  DERIVE_PERMISSIONS = 'derivePermissions',
}

export enum UIPermission {
  TOP_LEVEL_CONNECTIONS = 'topLevelConnections',
  MY_MONITORING = 'myMonitoring',
}

export enum StoreMutation {
  // Environment
  SET_ENV_VAR = 'setEnvironmentVar',
  SET_EXTERNAL_LINKS = 'setExternalLinks',

  // App view
  SET_APP_VIEW = 'setAppView',

  // Toasts
  SHOW_TOAST = 'showToast',
  DISMISS_TOAST = 'dismissToast',

  // Banners
  FETCH_BANNERS = 'fetchBanners',

  // Modal
  OPEN_MODAL = 'openModal',
  CLOSE_MODAL = 'closeModal',

  // Client
  SET_SELECTED_DRILLDOWN_CLIENT = 'setSelectedDrilldownClient',

  // Activity modal
  UPDATE_ACTIVITY_MODAL_CONTENT = 'updateActivityModalContent',
  START_ACTIVITY_MODAL_COUNTDOWN = 'startActivityModalCountdown',

  // Loading
  SET_LOADING_STATE = 'setLoadingState',
  SET_IS_APP_READY = 'setIsAppReady',

  // Slideover
  OPEN_SLIDEOVER = 'openSlideover',
  CLOSE_SLIDEOVER = 'closeSlideover',
  SET_SLIDEOVER_LOAD_ERROR = 'setInvitationSlideoverLoadError',
  SET_INVITATION_SLIDEOVER_DATA = 'setInvitationSlideoverData',

  CLEAR_INVITATION_SLIDEOVER_DATA = 'clearInvitationSlideoverData',

  // Pending invitations
  UPDATE_INVITATION_COUNT = 'updateInvitationCount',

  // Clients
  SET_CLIENT_LIST = 'setClientList',
  SET_SELECTED_CLIENT_KEY = 'setSelectedClientKey',

  // Clients
  SET_PERMISSION = 'setPermission',
}
