import { HttpRequest, HttpResponse } from '@/services/http/https-service-types';
import { BaseNotification, NotificationCategory, NotificationType } from '@/services/notification/notification-service-types';

export interface INotificationService {
  //-----------------------------------------------
  // Methods
  //-----------------------------------------------
  constructGetNotificationRequest: (category: NotificationCategory, filterTypes: NotificationType[], responseCallback: (response: HttpResponse<BaseNotification[]>) => void) => HttpRequest;
}

export const TYPES = {
  INotificationService: Symbol.for('INotificationService'),
};
