export interface Client {
  alias: string;
  clientKey: string;
  clientStatus: ClientStatus;
  appliedClientStatus: AppliedClientStatus;
  deviceId: string;
  planName?: string; // Optional for now, since we may not include for V1
  planStartDate: Date;
  planEndDate?: Date;
}

export enum ClientStatus {
  ACTIVE = 0,
  SUSPENDED = 1,
  DELETED = 2,
  DEFAULTED = 3,
  ARCHIVED = 4,
  PRE_ENROLLED = 5,
}

export enum AppliedClientStatus {
  UNKNOWN = 0,
  PRE_ENROLLED = 1,
  PENDING = 2,
  ACTIVE = 3,
  ARCHIVED = 4,
}

export const TYPES = {
  IClientService: Symbol.for('IClientService'),
};
