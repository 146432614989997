import { ErrorHandlers } from '@/services/http/https-service-types';
import { authService } from '@/services/services-collection';
import store from '@/store';
import router from '@/router';
import { StoreMutation } from '@/store/store-types';
import { IconType } from '@soberlink/soberlink-vue-library';
import { RouteName } from '@/router/router-types';

// ----------------------------------------------------------------
// Error handling for the entire app
// ------------------------------------------------------(----------
export const defaultErrorHandlers: ErrorHandlers = {
  0: () => showErrorToast(),
  401: () => authService.login(window.location.pathname),
  403: () => authService.forbidden(),
  500: () => showErrorToast(),
};

/**
 * A default error toast display callback
 * @param toastText
 */
export const showErrorToast = (toastText: string = 'An error occurred') => {
  store.commit(StoreMutation.SHOW_TOAST, {
    toastText,
    toastType: IconType.ERROR,
  });
};

/**
 * Handle HTTP errors by falling back to either the custom error handlers
 * (these take precedence), or,  failing that, the default error handlers.
 * @param error - the error response
 * @param customErrorHandlers - option custom error handlers to overwrite the default ones
 */
export function handleHttpError(error, customErrorHandlers?: ErrorHandlers) {
  // If there are custom error handlers, merge the default handlers with these (any custom values will overwrite
  // the default ones. Otherwise, if no custom handlers are present, use only the default error handlers.
  const errors = { ...defaultErrorHandlers, ...(customErrorHandlers || {}) };

  // Find the correct callback within the errors
  let errorCallback = errors[error?.response?.status];

  // If a code-specific handler is not found, default to the `0` catch-all error-handler
  if (!errorCallback) {
    errorCallback = errors[0];
  }

  errorCallback(error);

  // Rethrow the error so that it may be caught further down the line -
  // this step is important for UI proceedings and order of operations
  throw new Error(`${error?.title || ''}`);
}

// ----------------------------------------------------------------
// Service-specific error handling
// ----------------------------------------------------------------
/**
 * A util to generate both 500 and 0 (our notation for "catch-all") error handlers
 * @param callback - the callback to be applied to both 500 and 0 errors
 */
export function generate0And500ErrorHandlers(callback: () => any): ErrorHandlers {
  return {
    0: callback,
    500: callback,
  };
}

export const connectionCustomErrorHandlers = generate0And500ErrorHandlers(() => showErrorToast('An error occurred while getting your connections'));

export const notificationCustomErrorHandlers = generate0And500ErrorHandlers(() => showErrorToast('An error occurred while getting your notifications'));

export const userCustomErrorHandlers = {
  0: () => showErrorToast('An error occurred while getting user data'),
  500: async () => {
    await router.push(RouteName.UNAUTHORIZED);
  },
};

export const configCustomErrorHandlers = generate0And500ErrorHandlers(() => showErrorToast('An error occurred while getting config'));

export const userSettingsCustomErrorHandlers = generate0And500ErrorHandlers(() => showErrorToast('An error occurred while getting user settings'));
