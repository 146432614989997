export interface MediaSource {
  mediaKey: string;
  mediaType: MediaType;
}

export interface VideoPreviewData extends MediaSource {
  imageUrl: string;
  url: string;
  title: string;
  description: string;
  customButtonLabel?: string;
  customButtonCallback?: () => {};
}

export enum MediaType {
  VIDEO_PREVIEW = 0,
}

export const TYPES = {
  IMediaService: Symbol.for('IMediaService'),
};
