import { inject, injectable } from 'inversify';
import { IAuthService } from '@/services/auth/auth-service-interface';
import router from '@/router';
import type { IUserService } from '@/services/user/user-service-interface';
import { TYPES as userServiceTYPES, UserOptionsEnum, userOptionsMap } from '@/services/user/user-service-types';

@injectable()
export class AuthService implements IAuthService {
  //--------------------------------------------------
  // Fields
  //--------------------------------------------------

  private _userService: IUserService;
  private _loginAction: any;
  private _logoutAction: any;
  private _currentLogoutUrl: string;

  //--------------------------------------------------
  // Constructor
  //--------------------------------------------------

  constructor(@inject(userServiceTYPES.IUserService) userService: IUserService) {
    this._userService = userService;
    this._loginAction = () => { };
    this._logoutAction = () => { };
  }

  //--------------------------------------------------
  // async login(returnPath: string, loginHint: string)
  //--------------------------------------------------
  async login(returnPath: string) {
    if (returnPath === '/serveroutage') {
      await router.push('/serveroutage');
      return;
    }

    // return url
    const qsReturnUrl = `?returnUrl=${encodeURIComponent(returnPath)}`;

    // login hint
    const params = new URLSearchParams(window.location.search);
    const loginHint = params.get('loginHint');
    let qsloginHint = `&loginHint=${encodeURIComponent(loginHint)}`;
    if (!loginHint) {
      qsloginHint = '';
    }

    // login endpoints
    const loginEndpoint = `/bff/login${qsReturnUrl}${qsloginHint}`;
    window.location.replace(loginEndpoint);
  }

  //--------------------------------------------------
  // async logout()
  //--------------------------------------------------

  async logout() {
    window.location.replace('/bff/logout');
    return;
  }

  //--------------------------------------------------
  // async forbidden()
  //--------------------------------------------------

  async forbidden() {

    // login hint
    const params = new URLSearchParams(window.location.search);
    const loginHint = params.get('loginHint');
    let qsloginHint = `?loginHint=${encodeURIComponent(loginHint)}`;
    if (!loginHint) {
      qsloginHint = '';
    }

    window.location.replace(`/forbidden${qsloginHint}`);
    return;
  }

  //--------------------------------------------------
  // async logoutIdentityProvider()
  //--------------------------------------------------

  async logoutIdentityProvider() {
    const info = await this.getAuthenticationInfo(UserOptionsEnum.UPDATE_USER_NO_SLIDE);
    const logoutUrl = info.LogoutUrl;

    // redirect to /logoutsuccess either case
    if (logoutUrl) {
      window.location.replace(logoutUrl);
    } else {
      window.location.replace('/logoutsuccess');
    }
    return;
  }

  //--------------------------------------------------
  // async updateLogoutUrl
  //--------------------------------------------------
  async updateLogoutUrl(logoutUrl: string) {
    if (!logoutUrl) {
      return;
    }
    this._currentLogoutUrl = logoutUrl;
  }

  //--------------------------------------------------
  // async isAuthenticated
  //--------------------------------------------------
  async isAuthenticated(userOptions: UserOptionsEnum = UserOptionsEnum.DEFAULT): Promise<boolean> {
    const info = await this.getAuthenticationInfo(userOptions);
    const { slideSession } = userOptionsMap[`${userOptions}`];

    if (slideSession && info.SessionExpirationSec > 0) {
      this._loginAction();
    }
    await this.updateLogoutUrl(info.LogoutUrl);
    return info.SessionExpirationSec > 0;
  }

  //--------------------------------------------------
  // async getAuthenticationInfo()
  //--------------------------------------------------
  async getAuthenticationInfo(userOptions: UserOptionsEnum = UserOptionsEnum.DEFAULT) {
    const defaultResult = {
      SessionExpirationSec: -1,
      LogoutUrl: null,
    };

    try {
      const user = await this._userService.getUser(userOptions);
      return {
        SessionExpirationSec: user.SessionExpirationSec,
        LogoutUrl: user.LogoutUrl,
      };
    } catch (error) {
      return defaultResult;
    }
  }

  //----------------------
  // init
  //----------------------
  async init() { }

  //--------------------------------------------------
  // redirect(error: any)
  //--------------------------------------------------
  onUnAuthorized(error: any) {
    if (error.response.status === 401) {
      window.location.replace(`/bff/login?returnUrl=${encodeURIComponent(window.location.pathname)}`);
    }
  }

  //--------------------------------------------------
  // onLogin(action: any)
  //--------------------------------------------------
  onLogin(action: any) {
    this._loginAction = () => {
      action();
    };
  }

  //--------------------------------------------------
  // onLogout(action: any)
  //--------------------------------------------------
  onLogout(action: any) {
    this._logoutAction = () => {
      action();
    };
  }

  //--------------------------------------------------
  // END
  //--------------------------------------------------
}
