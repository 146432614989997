import { SlIconName } from '@soberlink/soberlink-vue-library';
import Contacts from '@/views/MyMonitoring/Contacts.vue';
import Reports from '@/views/Reports.vue';
import Device from '@/views/MyMonitoring/Device.vue';

export interface SidebarLink {
  link: SidebarLinkPage;
  label: string;
  iconName: SlIconName;
  openInNewTab?: boolean;
}

export enum SidebarLinkPage {
  HOME = '/',
  CONNECTIONS = '/connections',
  MY_MONITORING = '/my-monitoring',

  // Drilldown pages
  DEVICE = 'device',
  REPORTS = 'reports',
  CONTACTS = 'contacts',
}

export const drilldownLabelMap = {
  [`${SidebarLinkPage.DEVICE}`]: 'Device',
  [`${SidebarLinkPage.REPORTS}`]: 'Reports',
  [`${SidebarLinkPage.CONTACTS}`]: 'Contacts',
};

export const clientDrilldownSidebarLinks: SidebarLink[] = [
  {
    link: SidebarLinkPage.REPORTS,
    label: drilldownLabelMap[SidebarLinkPage.REPORTS],
    iconName: SlIconName.REPORT,
  },
];

export const myMonitoringSidebarLinks: SidebarLink[] = [
  {
    link: SidebarLinkPage.REPORTS,
    label: drilldownLabelMap[SidebarLinkPage.REPORTS],
    iconName: SlIconName.REPORT,
  },
  {
    link: SidebarLinkPage.DEVICE,
    label: drilldownLabelMap[SidebarLinkPage.DEVICE],
    iconName: SlIconName.DEVICE,
  },
  {
    link: SidebarLinkPage.CONTACTS,
    label: drilldownLabelMap[SidebarLinkPage.CONTACTS],
    iconName: SlIconName.CONTACTS,
  },
];
