/**
 * The type (category) of a given report
 */
export enum ReportType {
  CLIENT_DETAIL = 0,
  CASELOAD = 1,
}

/**
 * The type of date range
 */
export enum ReportDateRangeType {
  UNKNOWN = 0,
  DAILY = 1,
  WEEKLY = 2,
  MONTHLY = 3,
  CUMULATIVE = 4, // All-time history
  ON_DEMAND_RANGE = 5, // For defining a date range
}

/**
 * The format in which to provide the report
 * Note that, for now, we are only supporting PDF.
 */
export enum ReportFileFormat {
  UNKNOWN = 0,
  HTML = 1,
  PDF = 2,
  XLSX = 3,
}

export const TYPES = {
  IReportService: Symbol.for('IReportService'),
};
