import { injectable } from 'inversify';
import { HttpMethod, HttpRequest, HttpResponse } from '@/services/http/https-service-types';
import { SingleBatchResponse } from '@/services/batch/batch-service-types';
import { ReportDateRangeType, ReportFileFormat, ReportType } from '@/services/report/report-service-types';
import { IReportService } from '@/services/report/report-service-interface';

@injectable()
export class ReportService implements IReportService {
  //--------------------------------------------------
  // Constructor
  //--------------------------------------------------
  constructor() {}

  //--------------------------------------------------------------
  // constructGetClientDetailReportRequest
  //--------------------------------------------------------------
  constructGetClientDetailReportRequest(
    clientKey: string,
    dateRangeType: ReportDateRangeType,
    reportFileFormat: ReportFileFormat,
    startDate: Date | undefined,
    endDate: Date | undefined,
    responseCallback: (response: HttpResponse<File>) => File
  ): HttpRequest {
    let url = `/api/v1/reports/clientdetail/${clientKey}?ClientDetailReportType=${dateRangeType}&FileFormat=${reportFileFormat}`;

    if (startDate) {
      url += `&StartDate=${startDate}`;
    }

    if (endDate) {
      url += `&EndDate=${endDate}`;
    }

    return {
      url,
      method: HttpMethod.GET,
      batchResponseTypingCallback: (response: SingleBatchResponse<void>) => response.body as void,
      responseCallback,
    };
  }
}
