import { inject, injectable } from 'inversify';
import { User, UserOptionsEnum, userOptionsMap } from './user-service-types';
import type { IUserService } from '@/services/user/user-service-interface';
import { toUser } from '@/services/user/user-service-adapter';
import type { IHttpService } from '@/services/http/http-service-interface';
import { ErrorHandlers, HttpMethod, HttpRequest, HttpResponse, TYPES as httpServiceTYPES } from '@/services/http/https-service-types';
import type { IConfigService } from '@/services/config/config-service-interface';
import { TYPES as configServiceTYPES } from '@/services/config/config-service-types';

@injectable()
export class UserService implements IUserService {
  // --------------------------------------------------
  // Fields
  // --------------------------------------------------

  private _user: User = null;
  private _httpService: IHttpService;
  private _configService: IConfigService;
  private _thresholdSec: number;
  private _lastCachedTime: Date;
  private _customErrorHandlers: ErrorHandlers;

  // --------------------------------------------------
  // Constructor
  // --------------------------------------------------

  constructor(@inject(configServiceTYPES.IConfigService) configService: IConfigService, @inject(httpServiceTYPES.IHttpService) httpService: IHttpService) {
    this._httpService = httpService;
    this._configService = configService;
    this._thresholdSec = -1;
    this._lastCachedTime = new Date(1900, 1, 1);
  }

  //----------------------
  // init
  //----------------------
  async init(customErrorHandlers?: ErrorHandlers) {
    this._customErrorHandlers = customErrorHandlers;
    await this.getUser();
    if (!this._user) {
      throw new Error('@@@ Unable to get user');
    }
    return true;
  }

  // --------------------------------------------------
  // Method - Interface
  // --------------------------------------------------
  //----------------------
  // getUser: from cache
  //----------------------
  async getUser(userOptions: UserOptionsEnum = UserOptionsEnum.DEFAULT) {
    const { shouldHandleOnError, slideSession, flushCache } = userOptionsMap[`${userOptions}`];

    const isCacheObsolete = await this.isCacheObsolete();

    if (flushCache || isCacheObsolete) {
      this._user = await this._getUserObject(shouldHandleOnError, slideSession);
      this._lastCachedTime = new Date();
      this._user.RemainingSessionTimeSec = await this.getRemainingSessionTime();
    }

    return this._user;
  }

  // --------------------------------------------------
  // Private
  // --------------------------------------------------
  //-----------------
  // getUserObject
  //-----------------
  async _getUserObject(shouldHandleOnError = true, slideSession = true): Promise<User> {
    let queryString = `?slide=false`;
    if (slideSession) {
      queryString = '';
    }

    const url = `/bff/user${queryString}`;

    let user = null as User | null;

    const userRequest: HttpRequest = {
      url,
      method: HttpMethod.GET,
      shouldHandleOnError,
      batchResponseTypingCallback: (response: any) => response.data,
      responseCallback: (response: HttpResponse<User>) => (user = toUser(response)),
    };

    try {
      await this._httpService.sendRequest(userRequest, this._customErrorHandlers);
    } catch (error) {
      return null;
    }
    return user;
  }

  //-----------------
  // isCacheObsolete
  //-----------------
  async isCacheObsolete() {
    if (!this._user) {
      return true;
    }

    // Remaining session time
    this._user.RemainingSessionTimeSec = await this.getRemainingSessionTime();

    // Threshold
    this._thresholdSec = (await this._configService.getConfig()).SessionInactivityThresholdSec;

    return this._user.RemainingSessionTimeSec < this._thresholdSec;
  }

  //-----------------
  // getRemainingSessionTime
  //-----------------
  async getRemainingSessionTime() {
    if (!this._user) {
      return 0;
    }

    // How many seconds passed since this copy is cached
    const dteNow = Math.round(new Date().getTime());
    const lastCachedTime = Math.round(this._lastCachedTime.getTime());
    const timeElapsedSec = Math.round((dteNow - lastCachedTime) / 1000);

    // How many seconds remain in the current session
    let remainingSessionTimeSec = this._user.SessionExpirationSec - timeElapsedSec;
    if (remainingSessionTimeSec < 0) {
      remainingSessionTimeSec = 0;
    }
    return remainingSessionTimeSec;
  }

  // --------------------------------------------------
  // END
  // --------------------------------------------------
}
