// ----------------------------------------------------------------
// Import
// ----------------------------------------------------------------

import { createApp } from 'vue';
import App from './App.vue';
import SlideoverPlugin from '@/plugins/invitation-slideover-plugin';
import router from './router';
import store from './store';

// ----------------------------------------------------------------
// Import: Service Collection
// ----------------------------------------------------------------
import { activityService, configService, ServicesInit, userService, userSettingsService } from '@/services/services-collection';
import { clickOutsideDirective } from '@/directives/click-outside';
import { onIdleHandler, onStartHandler } from '@/utils/activity-service-initialization-utils';
import { userCustomErrorHandlers } from '@/utils/error-handling-utils';
import { StoreAction } from '@/store/store-types';

// ----------------------------------------------------------------
// App: Init
// ----------------------------------------------------------------

const app = createApp(App);

// Store
app.use(store);

// Plugins
app.use(SlideoverPlugin, store);

// Directives
app.directive('click-outside', clickOutsideDirective);

app.config.warnHandler = (msg, instance, trace) => {
  if (msg.includes('Missing required prop')) {
    // Temporarily disable this warning until Vue fixes Vue 3 `withDefaults` incompatibility
    return;
  }
};
ServicesInit().then(async () => {
  try {
    await configService.init();
    await userSettingsService.init();
    await userService.init(userCustomErrorHandlers);

    // activity service
    await activityService.onStart(onStartHandler);
    await activityService.onIdle(onIdleHandler);
    await activityService.start();
  } catch (e) {
    return;
  } finally {
    app.use(router);
    app.mount('#app');
  }
});
