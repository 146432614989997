import { inject, injectable } from 'inversify';
import type { IHttpService } from '@/services/http/http-service-interface';
import { HttpRequest, HttpMethod, TYPES as httpServiceTYPES, BatchHttpRequest, HttpResponse, ErrorHandlers } from '@/services/http/https-service-types';
import { IBatchService } from '@/services/batch/batch-service-interface';
import { BatchWrapper } from '@/services/batch/batch-service-types';
import { v4 as uuid } from 'uuid';
import { handleHttpError } from '@/utils/error-handling-utils';

@injectable()
export class BatchService implements IBatchService {
  //-----------------------------------------------
  // Fields
  //-----------------------------------------------

  private _httpService: IHttpService;

  //--------------------------------------------------
  // Constructor
  //--------------------------------------------------

  constructor(@inject(httpServiceTYPES.IHttpService) httpService: IHttpService) {
    this._httpService = httpService;
  }

  //--------------------------------------------------------------
  // sendBatchRequest
  //--------------------------------------------------------------

  async sendBatchRequest(requests: HttpRequest[], customErrorHandlers?: ErrorHandlers): Promise<void> {
    const requestsWithIds: BatchHttpRequest[] = [];

    // Loop through the requests and append a unique id to each one, as well as headers
    for (let i = 0; i < requests.length; i++) {
      requests[i].url = requests[i].url.replace(/^\/api/, "");
      requestsWithIds.push({
        id: uuid(),
        headers: {
          'content-type': 'application/json',
          Accept: '*/*',
          'odata-version': '4.0',
        },
        ...requests[i],
      } as BatchHttpRequest);
    }

    // Execute the batch request – note that there are not callbacks for the batch request as a whole – these are executed on each individual response below
    const url = '/api/batch';
    let res;
    await this._httpService.sendRequest(
      {
        url,
        method: HttpMethod.POST,
        body: { Requests: requestsWithIds },
        responseCallback: (response: HttpResponse<BatchWrapper>) => (res = response),
      },
      customErrorHandlers
    );

    for (let i = 0; i < res.data?.responses?.length; i++) {
      // After getting the array of responses, loop through them and execute the batchResponseTypingCallback and responseCallback on each individual response
      const curRequest = requests[i];
      const curResponse = res.data?.responses[i];
      let responseBody = curResponse?.body;

      // Check if the individual request has a non-2** level error
      const hasError = (/\d/.exec(curResponse?.status) || [])[0] !== '2';

      // If there is an error, handle it accordingly
      if (hasError && curRequest.shouldHandleOnError !== false) {
        handleHttpError(curResponse, customErrorHandlers);
      }

      // Execute the batch response typing callback to strongly type the response data
      if (curRequest.batchResponseTypingCallback) {
        responseBody = curRequest.batchResponseTypingCallback(curResponse);
      }

      // Note that a response is not returned from this request – the only way to access the response is via this callback
      if (curRequest.responseCallback) {
        curRequest.responseCallback({ ...curResponse, data: responseBody });
      }
    }
  }
}
