export const TYPES = {
  IActivityService: Symbol.for('IActivityService'),
};

export interface ActivityModalCallbacks {
  open: () => void;
  update: (popupMessage: string, popupTimer: number) => void;
  close: () => void;
  startCountdown: (activityModalPopUp: () => {}) => void;
}
