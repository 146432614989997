import { injectable } from 'inversify';
import { User, UserOptionsEnum, userOptionsMap } from './user-service-types';
import type { IUserService } from '@/services/user/user-service-interface';
import { ErrorHandlers } from '@/services/http/https-service-types';

@injectable()
export class MockUserService implements IUserService {
  // --------------------------------------------------
  // Constructor
  // --------------------------------------------------
  getRemainingSessionTimeLocal: () => number;
  isSessionTimeZeroLocal: () => Promise<boolean>;
  private _user: User = null;
  private _customErrorHandlers: ErrorHandlers;

  constructor() {}

  // --------------------------------------------------
  // Method - Interface: getUser
  // --------------------------------------------------
  async getUser(userOptions: UserOptionsEnum = UserOptionsEnum.DEFAULT) {
    const sessionExpirationSec = 30;

    const mockUser: User = {
      UserKey: '3nehfwjk-890e-n2kj-4399-i0dfjkl32023',
      FirstName: 'John',
      LastName: 'Doe',
      FullName: 'John Doe',
      Email: 'john.doe@gmail.com',
      TimeZoneOsName: 'Pacific Standard Time',
      Scopes: ['openid', 'profile', 'roles', 'service.atrium.connections.read', 'offline_access'],
      Roles: ['dev.sobersky.manage', 'dev.sobersky.client', 'sobersky.admin'],
      LogoutUrl: '/bff/logout%3Fsid=129C85BC37B069E646392704406E295B',
      SessionExpirationSec: sessionExpirationSec,
      RemainingSessionTimeSec: sessionExpirationSec,
      SessionState: 'F9U9Vw11GbzzkET5_CB5tJawDXPduK_Dmm0JQfbQf84.328851CFC0489C7B2489EE99554E4191',
    };

    this._user = mockUser;
    return this._user;
  }

  //----------------------
  // init
  //----------------------
  async init(customErrorHandlers?: ErrorHandlers) {
    this._customErrorHandlers = customErrorHandlers;
    return true;
  }

  // --------------------------------------------------
  // END
  // --------------------------------------------------
}
