import { injectable } from 'inversify';
import 'reflect-metadata';
import { HttpMethod, HttpRequest } from '@/services/http/https-service-types';
import { IUserSettingsService, UserSettings } from '@/services/user-settings/user-settings-service-interface';

//====================================================================
// Mock Implementation
//====================================================================

@injectable()
export class MockUserSettingsService implements IUserSettingsService {
  private _userSettings: UserSettings;

  //----------------------
  // init
  //----------------------
  async init() {
    const settings = await this.getUserSettings();
    if (!settings) {
      throw new Error('Settings service fails to initialize');
    }
  }

  async getUserSettings(): Promise<UserSettings> {
    if (!this._userSettings) {
      this._userSettings = new UserSettings();
    }

    return this._userSettings;
  }

  constructUpdateAutoAcceptInvitesRequest(autoAccept: boolean): HttpRequest {
    return {
      url: 'mock-update-auto-accept-invites-url',
      method: HttpMethod.PUT,
      batchResponseTypingCallback: () => {},
      responseCallback: () => (this._userSettings.userConnectionSettings.isAutoAcceptInvitation = autoAccept), // Update the current user settings state with the updated choice
    };
  }
}
