import { User } from './user-service-types';

export function toUser(res: any) {
  const result: User = {
    UserKey: res.data.UserKey,
    FirstName: res.data.FirstName,
    LastName: res.data.LastName,
    FullName: res.data.FullName,
    Email: res.data.Email,
    TimeZoneOsName: res.data.TimeZoneOsName,
    Scopes: res.data.Scopes,
    Roles: res.data.Roles,
    LogoutUrl: res.data.LogoutUrl,
    SessionExpirationSec: res.headers['session-expiration-sec'],
    RemainingSessionTimeSec: res.headers['session-expiration-sec'],
    SessionState: res.data.SessionState,
  };

  return result;
}
