import { inject, injectable } from 'inversify';
import { BaseNotification, NotificationCategory, NotificationType } from '@/services/notification/notification-service-types';

//-----------------------------------------------
// Http Service
//-----------------------------------------------
import type { IHttpService } from '@/services/http/http-service-interface';
import { HttpMethod, HttpRequest, HttpResponse, TYPES as httpServiceTYPES } from '@/services/http/https-service-types';
//-----------------------------------------------
// Activity Service
//-----------------------------------------------
import type { IActivityService } from '@/services/activity/activity-service-interface';
import { TYPES as activityServiceTYPES } from '@/services/activity/activity-service-types';

//-----------------------------------------------
// Wrapper service
//-----------------------------------------------
import { INotificationService } from '@/services/notification/notification-service-interface';
import { SingleBatchResponse } from '@/services/batch/batch-service-types';

@injectable()
export class NotificationService implements INotificationService {
  //-----------------------------------------------
  // Fields
  //-----------------------------------------------

  private _httpService: IHttpService;
  private _activityService: IActivityService;

  //--------------------------------------------------
  // Constructor
  //--------------------------------------------------

  constructor(@inject(httpServiceTYPES.IHttpService) httpService: IHttpService, @inject(activityServiceTYPES.IActivityService) activityService: IActivityService) {
    this._httpService = httpService;
    this._activityService = activityService;
  }

  //--------------------------------------------------------------
  // Methods: Interface
  //--------------------------------------------------------------

  //--------------------------------------------------------------
  // constructNotificationRequest
  //--------------------------------------------------------------
  constructGetNotificationRequest(category: NotificationCategory, filterTypes: NotificationType[], responseCallback: (response: HttpResponse<BaseNotification[]>) => void): HttpRequest {
      let url = '/api/v1/Notifications';

    // Add category specification, if required
    if (category) {
      if (category === NotificationCategory.BANNER) {
        url += '/banners';
      } else if (category === NotificationCategory.CONNECTION_INVITE) {
        url += '/connections';
      } else {
        console.warn('Unable to determine category from parameters');
      }
    }

    // Add type filter specification, if required
    if (filterTypes) {
      // Escaped quotations marks are essential to achieve correct OData syntax
      // eslint-disable-next-line no-useless-escape
      const types = filterTypes.map((notificationType: NotificationType) => `\'${notificationType}\'`)?.join(',');
      url += `?filter= type in (${types})`;
    }

    return {
      url,
      method: HttpMethod.GET,
      batchResponseTypingCallback: (response: SingleBatchResponse<BaseNotification[]>) => response.body as BaseNotification[],
      responseCallback,
    };
  }
}
