import { injectable } from 'inversify';
import 'reflect-metadata';
import { Config, ExternalLink, IConfigService } from '@/services/config/config-service-interface';

//====================================================================
// Mock Implementation
//====================================================================

@injectable()
export class MockConfigService implements IConfigService {
  private _config: Config;

  async init() {}

  async getConfig(): Promise<Config> {
    if (!this._config) {
      const config: Config = new Config();

      config.Config.ExternalLinks = [
        {
          Name: 'Settings',
          Url: 'mock-account',
        },
        {
          Name: 'Billing',
          Url: 'mock-billing',
        },
        {
          Name: 'Support',
          Url: 'mock-support',
        },
      ] as ExternalLink[];
      config.SessionInactivityThresholdSec = 0.4;
      config.InactivityWarningPopupSec = 20;
      config.InactivityWarningLogoutPaddingSec = 10;
      config.EnvironmentName = 'Local';

      this._config = config;
    }

    return this._config;
  }

  async getExternalLinks(): Promise<ExternalLink[]> {
    const config = await this.getConfig();
    return config.Config.ExternalLinks;
  }
}
