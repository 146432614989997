import { injectable } from 'inversify';
import type { IPageableUtilityService } from '@/services/utility/pageable-service-interface';
import { Pageable } from '@soberlink/soberlink-vue-library';

@injectable()
export class PageableUtilityService implements IPageableUtilityService {
  //---------------------------------------
  // getQueryString
  //---------------------------------------
  getQueryString(pageable: Pageable): string {
    return this.makeQueryString(pageable);
  }

  //---------------------------------------
  // makeQueryString
  //---------------------------------------
  makeQueryString(pageable: Pageable) {
    const pageIndex = this.hasValue(pageable.pageIndex) ? `pageIndex=${pageable.pageIndex}` : null;
    const pageSize = this.hasValue(pageable.pageSize) ? `pageSize=${pageable.pageSize}` : null;
    const sortField = this.hasValue(pageable.sortField) ? `sortField=${pageable.sortField}` : null;
    const sortDirection = this.hasValue(pageable.sortDirection) ? `sortDirection=${pageable.sortDirection}` : null;

    return [pageIndex, pageSize, sortField, sortDirection].filter(this.hasValue).join('&');
  }

  //---------------------------------------
  // hasValue
  //---------------------------------------
  hasValue(value: any) {
    return value !== undefined && value !== null;
  }
}
