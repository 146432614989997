import { Pageable, PageableWrapper } from '@soberlink/soberlink-vue-library';
import { ConnectionRelationshipType, ConnectionStatus, ConnectionGroupByType, Connection, ConnectionGroup } from '@/services/connection/connection-service-types';
import { HttpRequest, HttpResponse } from '@/services/http/https-service-types';
import { ClientStatus } from '@/services/client/client-service-types';

export interface IConnectionService {
  //-----------------------------------------------
  // Methods
  //-----------------------------------------------
  constructConnectionsForContactRequest: (
    groupBy: ConnectionGroupByType | undefined,
    relationship: ConnectionRelationshipType | undefined,
    status: ConnectionStatus | undefined,
    clientStatus: ClientStatus[] | undefined,
    pageable: Pageable,
    responseCallback: (response: HttpResponse<PageableWrapper<ConnectionGroup>>) => void
  ) => HttpRequest;

  constructConnectionsForClientRequest: (
    clientKey: string,
    relationship: ConnectionRelationshipType | undefined,
    status: ConnectionStatus | undefined,
    clientStatus: ClientStatus[] | undefined,
    pageable: Pageable,
    responseCallback: (response: HttpResponse<PageableWrapper<Connection>>) => void
  ) => HttpRequest;

  constructConnectionByKeyRequest: (connectionKey: string, responseCallback: (response: HttpResponse<Connection>) => void) => HttpRequest;

  constructAcceptAllInvitesRequest: () => HttpRequest;

  constructConnectionStatusUpdateRequest: (connectionKey: string, connectionStatus: ConnectionStatus) => HttpRequest;
}

export const TYPES = {
  IConnectionService: Symbol.for('IConnectionService'),
};
