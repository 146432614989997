import store from '@/store';
import { StoreMutation } from '@/store/store-types';
import { Modals } from '@/types';
import { ActivityModalCallbacks } from '@/services/activity/activity-service-types';
import { activityService, authService } from '@/services/services-collection';


// ----------------------------------------------------------------
// Activity service modal callbacks
// ----------------------------------------------------------------
export const activityModalCallbacks: ActivityModalCallbacks = {
  open: () => store.commit(StoreMutation.OPEN_MODAL, Modals.ACTIVITY_MODAL),
  close: () => store.commit(StoreMutation.CLOSE_MODAL, Modals.ACTIVITY_MODAL),
  update: (popupMessage: string, popupTimer: number) => store.commit(StoreMutation.UPDATE_ACTIVITY_MODAL_CONTENT, popupMessage.replace('popupTimer', popupTimer.toString())),
  startCountdown: (activityModalPopUp: () => {}) => store.commit(StoreMutation.START_ACTIVITY_MODAL_COUNTDOWN, activityModalPopUp),
};


// ----------------------------------------------------------------
// Activity Service 
// ----------------------------------------------------------------
// register activity
const registerActivity = async () => { await activityService.registerActivity(); };

// add activity handler
const addActivityHandler = () => {
  window.addEventListener('keyup', registerActivity);
  window.addEventListener('click', registerActivity);
}

// remove activity handler
const removeActivityHandler = () => {
  window.removeEventListener('keyup', registerActivity);
  window.removeEventListener('click', registerActivity);
}

//  pop up windows
const popUp = async () => {
  const expireationTime = await activityService.getExpirationTime();
  const message = 'You have been inactive for a while. For your security, we will automatically sign you out in approximately popupTimer seconds. You may choose Continue to stay logged in or log out if you are done.';

  if (expireationTime <= 0) {
    await activityService.stop();
    await authService.logout();
    return;
  }

  activityModalCallbacks.close();
  activityModalCallbacks.update(message, expireationTime);
  activityModalCallbacks.open();
};

// event handler for start
export const onStartHandler = async () => {
  removeActivityHandler();
  addActivityHandler();
}

// event handler for idle
export const onIdleHandler = async () => {
  await activityService.pauseActivity();
  const intervalId = setInterval(popUp, 1000);
  return intervalId;
}