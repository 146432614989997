import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { AppView, EnvironmentVar, State, StoreAction, StoreMutation, UIPermission } from '@/store/store-types';
import { clientService, configService, connectionService, httpService, notificationService } from '@/services/services-collection';
import { HttpResponse } from '@/services/http/https-service-types';
import { useConnections } from '@/use/connections';
import { InvitationSlideoverData, ToastData } from '@/types/index';
import { v4 as uuid } from 'uuid';
import { BannerNotification, BaseNotification, NotificationCategory } from '@/services/notification/notification-service-types';
import { toUIBannerNotification } from '@/services/notification/notification-service-controller';
import { ExternalLink } from '@/services/config/config-service-interface';
import { Client } from '@/services/client/client-service-types';
import { PageableWrapper } from '@soberlink/soberlink-vue-library';
import { ConnectionGroup, ConnectionGroupByType, ConnectionRelationshipType } from '@/services/connection/connection-service-types';

const { getInvitations, connectionsPendingPageable } = useConnections();

// -------------------------------------------------
// State
// -------------------------------------------------
const state: State = {
  environment: undefined,
  toasts: [],
  banners: [],
  activityModal: {
    timerIntervalId: -1,
    content: null,
  },
  selectedDrilldownClient: undefined,
  openModals: new Set(),
  openSlideovers: new Set(),
  invitationSlideoverData: {
    date: undefined,
    connectionKey: undefined,
    notificationKey: undefined,
    name: undefined,
    loadError: false,
  },
  appView: undefined as AppView | undefined,
  invitationCount: undefined,
  isLoading: false,
  externalLinks: undefined,
  myMonitoring: {
    clientList: undefined,
    selectedClientKey: undefined,
  },
  permissions: {
    topLevelConnections: false,
    myMonitoring: false,
  },
  isAppReady: false,
};

// -------------------------------------------------
// State: init
// -------------------------------------------------
export const store = createStore({
  plugins: [
    createPersistedState({
      paths: ['user'],
    }),
  ],
  state,
  getters: {
    selectedClient(state: State) {
      return state.myMonitoring?.clientList?.find((client: Client) => client.clientKey === state.myMonitoring?.selectedClientKey);
    },
  },
  actions: {
    async initialize() {
      store.commit(StoreMutation.SET_IS_APP_READY, false);
      await store.dispatch(StoreAction.GET_EXTERNAL_LINKS);
      await store.dispatch(StoreAction.GET_ENV_VAR);
      await store.dispatch(StoreAction.DERIVE_PERMISSIONS);
      await store.dispatch(StoreAction.FETCH_PENDING_INVITATION_COUNT);
      await store.dispatch(StoreAction.BANNERS);
      store.commit(StoreMutation.SET_IS_APP_READY, true);
    },

    async banners() {
      store.commit(StoreMutation.FETCH_BANNERS);
    },

    async getExternalLinks() {
      let externalLinks;
      try {
        externalLinks = await configService.getExternalLinks();
      } catch (e) {
        console.error(`Error while fetching external links: ${e}`);
      }
      store.commit(StoreMutation.SET_EXTERNAL_LINKS, externalLinks);
    },

    async getEnvironmentVar() {
      let env;
      try {
        env = (await configService.getConfig()).EnvironmentName.toLowerCase();
      } catch (e) {
        console.error(`Failed to fetch environment var from config service. Error: ${e}`);
      }

      store.commit(StoreMutation.SET_ENV_VAR, env);
    },

    /**
     * Fetch the number of pending invitations
     */
    async fetchPendingInvitationCount() {
      let count = 0;
      try {
        await getInvitations(0, {
          500: () => {},
        });
        count = connectionsPendingPageable.value?.totalItems;
      } catch (e) {
        console.error(`Failed to fetch connection invites: ${e}`);
      }
      store.commit(StoreMutation.UPDATE_INVITATION_COUNT, count);
    },

    /**
     * Fetch the number of pending invitations
     */
    async prepareClientList() {
      let clientList;
      try {
        const clientListRequest = clientService.constructGetClientListRequest([], (response: HttpResponse<Client[]>) => (clientList = response.data));
        await httpService.sendRequest(clientListRequest);
      } catch (e) {
        console.error(`Failed to fetch client list: ${e}`);
      }
      // Set the client list
      store.commit(StoreMutation.SET_CLIENT_LIST, clientList);

      // Set the MyMonitoring permission, based on whether the user has at least one client
      store.commit(StoreMutation.SET_PERMISSION, {
        permission: UIPermission.MY_MONITORING,
        hasPermission: !!clientList.length,
      });

      // If there is a client list, set the default selected client as the first client in the list
      if (clientList.length) {
        const selectedClient = store.state.myMonitoring?.selectedClientKey || clientList[0].clientKey;
        store.commit(StoreMutation.SET_SELECTED_CLIENT_KEY, selectedClient);
      }
    },

    /**
     * Derive the logged-in user's permissions
     */
    async derivePermissions() {
      // Top-Level Connections
      let hasTopLevelConnections;
      try {
        const clientListRequest = connectionService.constructConnectionsForContactRequest(
          ConnectionGroupByType.CLIENT,
          ConnectionRelationshipType.CLIENT,
          undefined,
          undefined,
          { pageSize: 1 as number },
          (response: HttpResponse<PageableWrapper<ConnectionGroup>>) => (hasTopLevelConnections = !!response.data?.totalItems)
        );
        await httpService.sendRequest(clientListRequest);
      } catch (e) {
        console.error(`Failed to derive Top-Level Connections permission: ${e}`);
      }

      store.commit(StoreMutation.SET_PERMISSION, {
        permission: UIPermission.TOP_LEVEL_CONNECTIONS,
        hasPermission: hasTopLevelConnections,
      });

      // My Monitoring
      // Check if we have already pulled the client list; if not, fetch it now and derive the correct permissions
      if (!state.myMonitoring.clientList) {
        await store.dispatch(StoreAction.PREPARE_CLIENT_LIST);
      }
    },
  },

  mutations: {
    // -------------------------------------------------
    // Environment
    // -------------------------------------------------
    setEnvironmentVar(state: State, env: EnvironmentVar) {
      state.environment = env;
    },

    setExternalLinks(state: State, externalLinks: ExternalLink[]) {
      state.externalLinks = externalLinks;
    },

    // -------------------------------------------------
    // App views
    // -------------------------------------------------
    setAppView(state: State, appView: AppView) {
      state.appView = appView;
    },

    // -------------------------------------------------
    // Toasts
    // -------------------------------------------------
    showToast(state: State, payload: Omit<ToastData, 'toastKey'>) {
      const { toastText, toastType, dismissable } = payload;
      const toastKey = uuid();

      const newAlert: ToastData = {
        toastKey,
        toastText,
        toastType,
        dismissable,
      };
      state.toasts.push(newAlert);
      if (!!newAlert.dismissable === false) {
        setTimeout(() => {
          store.commit(StoreMutation.DISMISS_TOAST, newAlert.toastKey);
        }, 5000);
      }
    },

    dismissToast(state: State, toastKey: string) {
      const { toasts } = state;
      state.toasts = toasts.filter((toast: ToastData) => toast.toastKey !== toastKey);
    },

    async fetchBanners() {
      let banners;
      try {
        // Fetch the banners and convert them to UI-ready versions
        const responseCallback = (response: HttpResponse<BaseNotification[]>) =>
          (banners = response?.data?.map((banner: BaseNotification) => toUIBannerNotification(banner as BannerNotification, store.state.externalLinks)));
        const bannersRequest = notificationService.constructGetNotificationRequest(NotificationCategory.BANNER, undefined, responseCallback);
        await httpService.sendRequest(bannersRequest);
      } catch (e) {
        console.error(`Something went wrong while trying to fetch user alerts: ${e}`);
      }

      store.state.banners = banners;
    },

    // -------------------------------------------------
    // Selected client
    // -------------------------------------------------
    setSelectedDrilldownClient(state: State, client: Client | undefined) {
      state.selectedDrilldownClient = client;
    },

    // -------------------------------------------------
    // Modals
    // -------------------------------------------------
    openModal(state: State, modalId: string) {
      state.openModals.add(modalId);
    },

    closeModal(state: State, modalId: string) {
      state.openModals.delete(modalId);
    },

    // -------------------------------------------------
    // Activity modal
    // -------------------------------------------------
    updateActivityModalContent(state: State, content: string) {
      state.activityModal.content = content;
    },

    startActivityModalCountdown(state: State, payload: any) {
      const startNewCountDown = payload;
      clearInterval(state.activityModal.timerIntervalId);
      state.activityModal.timerIntervalId = startNewCountDown();
    },

    // -------------------------------------------------
    // Loading
    // -------------------------------------------------
    setLoadingState(state: State, isLoading: boolean) {
      state.isLoading = isLoading;
    },

    setIsAppReady(state: State, isReady: boolean) {
      state.isAppReady = isReady;
    },

    // -------------------------------------------------
    // Slideover
    // -------------------------------------------------
    openSlideover(state: State, slideoverId: string) {
      state.openSlideovers.add(slideoverId);
    },

    closeSlideover(state: State, slideoverId: string) {
      state.openSlideovers.delete(slideoverId);
    },

    setInvitationSlideoverLoadError(state: State, hasError: boolean) {
      state.invitationSlideoverData.loadError = hasError;
    },

    clearInvitationSlideoverData(state: State) {
      state.invitationSlideoverData = {};
    },

    setInvitationSlideoverData(state: State, data: InvitationSlideoverData) {
      state.invitationSlideoverData = { ...state.invitationSlideoverData, ...data };
    },

    // -------------------------------------------------
    // Pending invitations
    // -------------------------------------------------
    updateInvitationCount(state: State, count: number) {
      state.invitationCount = count;
    },

    // -------------------------------------------------
    // Clients
    // -------------------------------------------------
    setClientList(state: State, clientList: Client[] | undefined) {
      state.myMonitoring.clientList = clientList;
    },

    setSelectedClientKey(state: State, clientKey: string | undefined) {
      state.myMonitoring.selectedClientKey = clientKey;
    },

    // -------------------------------------------------
    // Permissions
    // -------------------------------------------------
    setPermission(state: State, data: { permission: UIPermission; hasPermission: boolean }) {
      if (data.permission !== undefined && data.permission !== null) {
        state.permissions[`${data.permission}`] = data.hasPermission;
      }
    },
  },
});

export default store;
