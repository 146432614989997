import { SingleBatchResponse } from '@/services/batch/batch-service-types';

export type HttpError = {
  statusCode: number;
  errorHandler: any;
};

export const TYPES = {
  IHttpService: Symbol.for('IHttpService'),
};

export interface HttpRequest {
  method: string;
  url: string;
  headers?: any;
  body?: any;
  shouldHandleOnError?: boolean;
  batchResponseTypingCallback?: (response: SingleBatchResponse<any>) => any; // A callback, used in batch requests, to extract the strongly typed body from the batch response
  responseCallback?: (response: HttpResponse<any>) => void; // A callback to assign the body to a variable. Note that a response is not returned from either HTTP or Batch requests – the only way to access the response is via this callback
}

export interface HttpResponse<T> {
  status: number;
  headers: any;
  data: T;
}

export interface BatchHttpRequest extends HttpRequest {
  id: string;
}

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

export interface ErrorHandlers {
  0?: (error) => void;
  400?: (error) => void;
  401?: (error) => void;
  404?: (error) => void;
  403?: (error) => void;
  500?: (error) => void;
}
