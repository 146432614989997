import { injectable } from 'inversify';
import { HttpMethod, HttpRequest, HttpResponse } from '@/services/http/https-service-types';
import { SingleBatchResponse } from '@/services/batch/batch-service-types';
import { VideoPreviewData } from '@/services/media/media-service-types';
import { IMediaService } from '@/services/media/media-service-interface';

@injectable()
export class MediaService implements IMediaService {
  //--------------------------------------------------
  // Constructor
  //--------------------------------------------------
  constructor() {}

  //--------------------------------------------------------------
  // constructGetDeviceMediaRequest
  //--------------------------------------------------------------
  constructGetDeviceMediaRequest(deviceKey: string, responseCallback: (response: HttpResponse<VideoPreviewData[]>) => void): HttpRequest {
    const url = `/api/v1/media/device/${deviceKey}`;

    return {
      url,
      method: HttpMethod.GET,
      batchResponseTypingCallback: (response: SingleBatchResponse<VideoPreviewData[]>) => response.body as VideoPreviewData[],
      responseCallback,
    };
  }
}
