import { injectable } from 'inversify';
import type { IActivityService } from '@/services/activity/activity-service-interface';

@injectable()
export class ActivityServiceMock implements IActivityService {
  constructor() { }
  start: () => Promise<void>;
  stop: () => Promise<void>;
  registerActivity: () => Promise<void>;
  pauseActivity: () => Promise<void>;
  continueActivity: () => Promise<void>;
  async getExpirationTime() { return 300; }
  onStart: (onStartHandler: () => Promise<void>) => void;
  onStop: (onStopHandler: () => Promise<void>) => void;
  onExpired: (onExpiredHandler: () => Promise<void>) => void;
  onIdle: (onIdleHandler: () => Promise<number>) => Promise<void>;

}
