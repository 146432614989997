import { BannerNotification, BannerNotificationLocation, NotificationType, UIBannerNotification } from '@/services/notification/notification-service-types';
import { ExternalLink, ExternalLinkName } from '@/services/config/config-service-interface';

/**
 * A transformer to take notification data coming from the NotificationService and supplement it
 * with UI-specific data (i.e. button text, button link and the location of the banner
 * @param banner - a raw banner object from the NotificationService
 * @param externalLinks - static external link data
 */
export function toUIBannerNotification(banner: BannerNotification, externalLinks: ExternalLink[]): UIBannerNotification {
  if (banner.type === NotificationType.NO_VALID_PAYMENT_METHOD) {
    return {
      ...banner,
      buttonText: 'Update Payment Details',
      buttonUrl: externalLinks?.find((link: ExternalLink) => link.Name === ExternalLinkName.BILLING)?.Url,
      bannerLocation: BannerNotificationLocation.GLOBAL,
    };
  } else if (banner.type === NotificationType.CHANGE_DEVICE) {
    return {
      ...banner,
      bannerLocation: BannerNotificationLocation.HOME,
    };
  }
}
