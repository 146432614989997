import { ClientStatus } from '@/services/client/client-service-types';
import { StatusPillVariation } from '@soberlink/soberlink-vue-library';

export function determineClientStatusLabel(status: ClientStatus) {
  switch (status) {
    case ClientStatus.ACTIVE:
      return 'Active';
    case ClientStatus.SUSPENDED:
      return 'Suspended';
    case ClientStatus.DELETED:
      return 'Deleted';
    case ClientStatus.DEFAULTED:
      return 'Defaulted';
    case ClientStatus.ARCHIVED:
      return 'Archived';
    case ClientStatus.PRE_ENROLLED:
      return 'Pre-enrolled';
    default:
      return '';
  }
}

export function determineClientStatusColor(status: ClientStatus) {
  switch (status) {
    case ClientStatus.PRE_ENROLLED:
      return StatusPillVariation.BLUE;
    case ClientStatus.SUSPENDED:
      return StatusPillVariation.YELLOW;
    case ClientStatus.ACTIVE:
      return StatusPillVariation.GREEN;
    case ClientStatus.ARCHIVED:
    case ClientStatus.DELETED:
      return StatusPillVariation.GRAY;
    default:
      return StatusPillVariation.GRAY;
  }
}
