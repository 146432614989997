// -------------------------------------------------
// IMPORT
// -------------------------------------------------
// -------------------------------------------------
// Import: Container
// -------------------------------------------------

import { ServicesContainer } from '@/services/container';

// -------------------------------------------------
// Import: Config Service
// -------------------------------------------------
import type { IConfigService } from '@/services/config/config-service-interface';
import { TYPES as configServiceTYPES } from '@/services/config/config-service-types';

// -------------------------------------------------
// Import: User Settings Service
// -------------------------------------------------
import type { IUserSettingsService } from '@/services/user-settings/user-settings-service-interface';
import { TYPES as userSettingsServiceTYPES } from '@/services/user-settings/user-settings-service-types';

// -------------------------------------------------
// Import: Batch Service
// -------------------------------------------------
import type { IBatchService } from '@/services/batch/batch-service-interface';
import { TYPES as batchServiceTYPES } from '@/services/batch/batch-service-interface';

// -------------------------------------------------
// Import: Authentication Services
// -------------------------------------------------
import type { IAuthService } from '@/services/auth/auth-service-interface';
import { TYPES as authServiceTYPES } from '@/services/auth/auth-service-types';

// -------------------------------------------------
// Import: Connection Services
// -------------------------------------------------
import { IConnectionService, TYPES as connectionServiceTYPES } from '@/services/connection/connection-service-interface';

// -------------------------------------------------
// Import: PageableUtility Services
// -------------------------------------------------
import { TYPES as clientServiceTYPES } from '@/services/client/client-service-types';
import { IClientService } from '@/services/client/client-service-interface';

// -------------------------------------------------
// Import: Notification Services
// -------------------------------------------------
import { INotificationService, TYPES as notificationServiceTYPES } from '@/services/notification/notification-service-interface';

// -------------------------------------------------
// Import: User Services
// -------------------------------------------------
import type { IUserService } from '@/services/user/user-service-interface';
import { TYPES as userServiceTYPES } from '@/services/user/user-service-types';
// -------------------------------------------------
// Import: Http Services
// -------------------------------------------------
import type { IHttpService } from '@/services/http/http-service-interface';
import { TYPES as httpServiceTYPES } from '@/services/http/https-service-types';

// -------------------------------------------------
// Import: Activity Services
// -------------------------------------------------
import type { IActivityService } from '@/services/activity/activity-service-interface';
import { TYPES as activityServiceTYPES } from '@/services/activity/activity-service-types';

// -------------------------------------------------
// Import: Media Services
// -------------------------------------------------
import type { IMediaService } from '@/services/media/media-service-interface';
import { TYPES as mediaServiceTYPES } from '@/services/media/media-service-types';

// -------------------------------------------------
// Import: Device Services
// -------------------------------------------------
import type { IDeviceService } from '@/services/device/device-service-interface';
import { TYPES as deviceServiceTYPES } from '@/services/device/device-service-types';

// -------------------------------------------------
// Import: PageableUtility Services
// -------------------------------------------------
import type { IPageableUtilityService } from '@/services/utility/pageable-service-interface';
import { TYPES as pageableUtilityServiceTYPES } from '@/services/utility/pageable-service-types';

// -------------------------------------------------
// Import: PageableUtility Services
// -------------------------------------------------
import { IReportService } from '@/services/report/report-service-interface';
import { TYPES as reportServiceTYPES } from '@/services/report/report-service-types';

// -------------------------------------------------
// EXPORT
// -------------------------------------------------

// -------------------------------------------------
// Export: Config Service
// -------------------------------------------------

export const configService = ServicesContainer.get<IConfigService>(configServiceTYPES.IConfigService);

// -------------------------------------------------
// Export: User Settings Service
// -------------------------------------------------

export const userSettingsService = ServicesContainer.get<IUserSettingsService>(userSettingsServiceTYPES.IUserSettingsService);

// -------------------------------------------------
// Export: Authentication Service
// -------------------------------------------------

export const authService = ServicesContainer.get<IAuthService>(authServiceTYPES.IAuthService);

// -------------------------------------------------
// Export: Connection Service
// -------------------------------------------------

export const notificationService = ServicesContainer.get<INotificationService>(notificationServiceTYPES.INotificationService);

// -------------------------------------------------
// Export: Client Service
// -------------------------------------------------

export const clientService = ServicesContainer.get<IClientService>(clientServiceTYPES.IClientService);

// -------------------------------------------------
// Export: Connection Service
// -------------------------------------------------

export const connectionService = ServicesContainer.get<IConnectionService>(connectionServiceTYPES.IConnectionService);

// -------------------------------------------------
// Export: User Service
// -------------------------------------------------

export const userService = ServicesContainer.get<IUserService>(userServiceTYPES.IUserService);

// -------------------------------------------------
// Export: Batch Service
// -------------------------------------------------

export const batchService = ServicesContainer.get<IBatchService>(batchServiceTYPES.IBatchService);

// -------------------------------------------------
// Export: Media Service
// -------------------------------------------------

export const mediaService = ServicesContainer.get<IMediaService>(mediaServiceTYPES.IMediaService);

// -------------------------------------------------
// Export: DeviceService
// -------------------------------------------------

export const deviceService = ServicesContainer.get<IDeviceService>(deviceServiceTYPES.IDeviceService);

// -------------------------------------------------
// Export: Http Service
// -------------------------------------------------

export const httpService = ServicesContainer.get<IHttpService>(httpServiceTYPES.IHttpService);

// -------------------------------------------------
// Export: Activity Service
// -------------------------------------------------

export const activityService = ServicesContainer.get<IActivityService>(activityServiceTYPES.IActivityService);

// -------------------------------------------------
// Export: Report Service
// -------------------------------------------------

export const reportService = ServicesContainer.get<IReportService>(reportServiceTYPES.IReportService);

// -------------------------------------------------
// Export: Http Service
// -------------------------------------------------

export const pageableUtilityService = ServicesContainer.get<IPageableUtilityService>(pageableUtilityServiceTYPES.IPageableUtilityService);

// -------------------------------------------------
// ServicesInit()
// -------------------------------------------------

export async function ServicesInit() {}
