import { inject, injectable } from 'inversify';
import type { IActivityService } from '@/services/activity/activity-service-interface';

import type { IConfigService } from '@/services/config/config-service-interface';
import { TYPES as configServiceTYPES } from '@/services/config/config-service-types';

import type { IUserService } from '@/services/user/user-service-interface';
import { TYPES as userServiceTYPES, UserOptionsEnum } from '@/services/user/user-service-types';

@injectable()
export class ActivityService implements IActivityService {
  //--------------------------------------------------
  // Required Services
  //--------------------------------------------------

  private _configService: IConfigService;

  private _userService: IUserService;

  //--------------------------------------------------
  // Internal Bookkeeping
  //--------------------------------------------------

  // session expires at this moment
  private _sessionExpirationTime = 0;

  // interval id for session countdown
  private _sessionCountDownId = -1;

  // handle idle at this moment
  private _idleWarningTime = 0;

  // interval id for handling idle
  private _idleWarningCountDownId = -1;

  // is activity service pause
  private _isActivityPause = false;

  // event handler for start
  private _onStartHandler = async () => {};

  // event handler for stop
  private _onStopHandler = async () => {};

  // event handler for idle
  private _onIdleHandler = async () => {
    return -1;
  };

  // for every second elapsed, check if any user activity has been detected
  private _sessionCountDown = async () => {
    if (Date.now() > this._idleWarningTime && (await this.isIdleDetected())) {
      clearInterval(this._sessionCountDownId);
      this._idleWarningCountDownId = await this._onIdleHandler();
    }
  };

  //--------------------------------------------------
  // Constructor
  //--------------------------------------------------
  constructor(@inject(configServiceTYPES.IConfigService) configService: IConfigService, @inject(userServiceTYPES.IUserService) userService: IUserService) {
    this._configService = configService;
    this._userService = userService;
  }

  //--------------------------------------------------
  // Interface
  //--------------------------------------------------

  //----------------------
  // start
  //----------------------
  async start() {
    await this._onStartHandler();

    // clear every timer
    clearInterval(this._sessionCountDownId);
    clearInterval(this._idleWarningCountDownId);

    // resume activity tracking
    this.continueActivity();

    // initialize session time
    await this.updateSession();

    // start session count down
    this._sessionCountDownId = setInterval(this._sessionCountDown, 1000);
  }

  //----------------------
  // stop
  //----------------------
  async stop() {
    // clear every timer
    clearInterval(this._sessionCountDownId);
    clearInterval(this._idleWarningCountDownId);

    await this._onStopHandler();
  }

  //----------------------
  // registerActivity
  //----------------------
  async registerActivity() {
    if (!this._isActivityPause) {
      await this.updateSession();
    }
  }

  //----------------------
  // pauseActivity
  //----------------------
  async pauseActivity() {
    this._isActivityPause = true;
  }

  //----------------------
  // continueActivity
  //----------------------
  async continueActivity() {
    this._isActivityPause = false;
  }

  //----------------------
  // getExpirationTime
  //----------------------
  async getExpirationTime() {
    const paddingSec = (await this._configService.getConfig()).InactivityWarningLogoutPaddingSec;
    const expirationTime = Math.ceil((this._sessionExpirationTime - Date.now()) / 1000) - paddingSec;
    return expirationTime;
  }

  //--------------------------------------------------
  // add start handler
  //--------------------------------------------------
  async onStart(onStartHandler: () => Promise<void>) {
    this._onStartHandler = onStartHandler;
  }

  //--------------------------------------------------
  // add stop handler
  //--------------------------------------------------
  async onStop(onStopHandler: () => Promise<void>) {
    this._onStopHandler = onStopHandler;
  }

  //--------------------------------------------------
  // add idle handler
  //--------------------------------------------------
  public async onIdle(onIdleHandler: () => Promise<number>) {
    this._onIdleHandler = onIdleHandler;
  }

  //----------------------
  // update session
  //----------------------
  async updateSession(userOptions: UserOptionsEnum = UserOptionsEnum.DEFAULT) {
    const user = await this._userService.getUser(userOptions);
    const settings = await this._configService.getConfig();

    // set session info
    this._sessionExpirationTime = Date.now() + user.RemainingSessionTimeSec * 1000;
    this._idleWarningTime = this._sessionExpirationTime - settings.InactivityWarningPopupSec * 1000 - settings.InactivityWarningLogoutPaddingSec * 1000;
  }

  //----------------------
  // isIdleDetected
  //----------------------
  private async isIdleDetected() {
    try {
      // fetch latest user info without sliding
      const user = await this._userService.getUser(UserOptionsEnum.UPDATE_USER_NO_SLIDE);
      const settings = await this._configService.getConfig();

      // set session info
      this._sessionExpirationTime = Date.now() + user.RemainingSessionTimeSec * 1000;
      this._idleWarningTime = this._sessionExpirationTime - settings.InactivityWarningPopupSec * 1000 - settings.InactivityWarningLogoutPaddingSec * 1000;

      const result = Date.now() > this._idleWarningTime;
      return result;
    } catch (error: any) {
      return true;
    }
  }
}
