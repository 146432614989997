export type User = {
  UserKey: string;
  FirstName: string;
  LastName: string;
  FullName: string;
  Email: string;
  TimeZoneOsName: string;
  Scopes: string[];
  Roles: string[];
  LogoutUrl: string;
  SessionExpirationSec: number;
  RemainingSessionTimeSec: number;
  SessionState: string;
};

export const TYPES = {
  IUserService: Symbol.for('IUserService'),
};

export enum UserOptionsEnum {
  DEFAULT = 'default', // User activity is detected, update the session time if cache expires already. This scenario is caused by user interaction.
  UPDATE_USER_NO_SLIDE = 'update-user-no-slide', // Ping backend to see if session has been updated elsewhere. This scenario is not caused by user interaction.
}

export interface UserOptions {
  shouldHandleOnError: boolean;
  // - whether the default error handler should step in case of error status, e.g. 401 / 404 / 500 etc
  // - used by http service, user service act as a pass through
  // - true = in case of an HTTP error, let default handler take care of the flow (e.g. redirect to login page)
  // - false = in case of an HTTP error, default handler should not disrupt the flow, i.e. I don't want to redirect to login page
  // e.g. when session timer wants to check remaining time, should not abruptly redirect to login page

  slideSession: boolean;
  // - whether the backend should extend the current session to prevent user being logged out
  // - true = extend the session because user is actively using the application
  // e.g. user click, API call
  // - false = backend should not extend current session
  // e.g. activity service pinging the backend for updated session info does not represent legitimate user interaction

  flushCache: boolean;
  // - whether to disregard local cache expiration time and talk to backend
  // - true = talk to backend for an updated user session info, even when local cache is NOT expired
  // e.g. when there are 40 seconds remained in the local cache, check against backend to see if session time has been
  // extended somewhere else. If not, a warning will pop up. Otherwise, suppress the warning and update the cache expiration time
  // - false = do not talk to backend when there is still time in the cache
}

// Used to map different UserOptionsEnums to their respective user options
export const userOptionsMap: { [key: string]: UserOptions } = {
  [`${UserOptionsEnum.DEFAULT}`]: {
    shouldHandleOnError: true,
    slideSession: true,
    flushCache: false,
  },
  [`${UserOptionsEnum.UPDATE_USER_NO_SLIDE}`]: {
    shouldHandleOnError: false,
    slideSession: false,
    flushCache: true,
  },
};
