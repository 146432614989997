import { batchService, connectionService, httpService } from '@/services/services-collection';
import { Connection, ConnectionGroup, ConnectionGroupByType, ConnectionRelationshipType, ConnectionStatus } from '@/services/connection/connection-service-types';
import { ref, Ref } from 'vue';
import { Pageable, PageableWrapper, SortDirection } from '@soberlink/soberlink-vue-library';
import { ErrorHandlers, HttpResponse } from '@/services/http/https-service-types';
import { StoreMutation } from '@/store/store-types';
import store from '@/store';
import { connectionCustomErrorHandlers } from '@/utils/error-handling-utils';
import { ClientStatus } from '@/services/client/client-service-types';

export function useConnections() {
  // ----------------------------------------------------------------
  // Load error
  // ----------------------------------------------------------------
  const loadError = ref(false);

  // ----------------------------------------------------------------
  // Connection fetching refs
  // ----------------------------------------------------------------
  const connectionsPendingPageable: Ref<PageableWrapper<Connection> | undefined> = ref(undefined);
  const connectionsConcernedPageable: Ref<PageableWrapper<Connection> | undefined> = ref(undefined);
  const connectionsContactsPageable: Ref<PageableWrapper<Connection> | undefined> = ref(undefined);
  const connectionsMonitoredPageable: Ref<PageableWrapper<Connection> | undefined> = ref(undefined);
  const connectionsArchivedPageable: Ref<PageableWrapper<Connection> | undefined> = ref(undefined);

  // A default pageable that ensures that the pageIndex is explicitly set when calling APIs
  const defaultPageable: Omit<Pageable, 'pageIndex'> = {
    pageSize: 10 as number,
    sortField: 'email' as string,
    sortDirection: SortDirection.ASCENDING,
  };

  // ----------------------------------------------------------------
  // Connection param construction
  // ----------------------------------------------------------------
  function constructConnectionParams(relationship: ConnectionRelationshipType, status: ConnectionStatus, clientStatus: ClientStatus[], pageable: Pageable = {}) {
    return {
      relationship,
      status,
      clientStatus,
      pageable: {
        ...defaultPageable,
        pageSize: 10,
        pageIndex: 0,
        ...pageable, // override the default with any param pageable fields
      },
    };
  }

  const archivedParams = (pageIndex: number = 0) => constructConnectionParams(ConnectionRelationshipType.CLIENT, undefined, [ClientStatus.ARCHIVED], { pageIndex });
  const concernedParams = (pageIndex: number = 0) =>
    constructConnectionParams(ConnectionRelationshipType.CONCERNED_PARTY, ConnectionStatus.ACTIVE, [ClientStatus.ACTIVE, ClientStatus.PRE_ENROLLED, ClientStatus.SUSPENDED], { pageIndex });
  const contactParams = (pageIndex: number = 0) =>
    constructConnectionParams(ConnectionRelationshipType.CONTACT, ConnectionStatus.ACTIVE, [ClientStatus.ACTIVE, ClientStatus.PRE_ENROLLED, ClientStatus.SUSPENDED], {
      pageIndex,
    });
  const monitoredParams = (pageIndex: number = 0) =>
    constructConnectionParams(ConnectionRelationshipType.CLIENT, ConnectionStatus.ACTIVE, [ClientStatus.ACTIVE, ClientStatus.PRE_ENROLLED, ClientStatus.SUSPENDED], {
      pageIndex,
    });
  const invitationParams = (pageIndex: number = 0) =>
    constructConnectionParams(ConnectionRelationshipType.CLIENT, ConnectionStatus.PENDING_ACCEPTANCE, undefined, {
      pageIndex,
      pageSize: 8,
    });

  // ----------------------------------------------------------------
  // Connection assignment callbacks
  // ----------------------------------------------------------------
  const contactResponseCallback = (response: HttpResponse<PageableWrapper<Connection>>) => (connectionsContactsPageable.value = response.data);
  const concernedResponseCallback = (response: HttpResponse<PageableWrapper<Connection>>) => (connectionsConcernedPageable.value = response.data);
  const monitoredResponseCallback = (response: HttpResponse<PageableWrapper<ConnectionGroup>>) => (connectionsMonitoredPageable.value = response.data);
  const archivedResponseCallback = (response: HttpResponse<PageableWrapper<Connection>>) => (connectionsArchivedPageable.value = response.data);
  const invitationsResponseCallback = (response: HttpResponse<PageableWrapper<Connection>>) => {
    connectionsPendingPageable.value = response.data;
    store.commit(StoreMutation.UPDATE_INVITATION_COUNT, response.data.totalItems);
  };

  // ----------------------------------------------------------------
  // Connection fetching methods
  // ----------------------------------------------------------------
  /**
   * Provide a customErrorHandling param for this function since it is called
   * in three different places, all with different error handling
   * @param pageIndex
   * @param customErrorHandling
   */
  async function getInvitations(pageIndex?: number, customErrorHandling: ErrorHandlers = connectionCustomErrorHandlers) {
    try {
      const { clientStatus, relationship, status, pageable } = invitationParams(pageIndex);
      await httpService.sendRequest(
        connectionService.constructConnectionsForContactRequest(ConnectionGroupByType.CLIENT, relationship, status, clientStatus, pageable, invitationsResponseCallback),
        customErrorHandling
      );
    } catch (e) {
      console.error(`Failed to fetch invitation connections: ${e}`);
    }
  }

  async function getArchivedConnections(pageIndex?: number) {
    try {
      const { clientStatus, relationship, status, pageable } = archivedParams(pageIndex);
      await httpService.sendRequest(
        connectionService.constructConnectionsForContactRequest(ConnectionGroupByType.CLIENT, relationship, status, clientStatus, pageable, archivedResponseCallback),
        connectionCustomErrorHandlers
      );
    } catch (e) {
      console.error(`Failed to fetch archived connections: ${e}`);
    }
  }

  async function getMonitoredConnections(pageIndex?: number) {
    try {
      const { relationship, status, clientStatus, pageable } = monitoredParams(pageIndex);
      await httpService.sendRequest(
        connectionService.constructConnectionsForContactRequest(ConnectionGroupByType.CLIENT, relationship, status, clientStatus, pageable, monitoredResponseCallback),
        connectionCustomErrorHandlers
      );
    } catch (e) {
      console.error(`Failed to fetch monitored client connections: ${e}`);
    }
  }

  async function getConcernedConnections(clientKey: string, pageIndex?: number) {
    try {
      const { clientStatus, relationship, status, pageable } = concernedParams(pageIndex);
      await httpService.sendRequest(
        connectionService.constructConnectionsForClientRequest(clientKey, relationship, status, clientStatus, pageable, concernedResponseCallback),
        connectionCustomErrorHandlers
      );
    } catch (e) {
      console.error(`Failed to fetch concerned connections: ${e}`);
    }
  }

  async function getContactConnections(clientKey: string, pageIndex?: number) {
    try {
      const { clientStatus, relationship, status, pageable } = contactParams(pageIndex);
      await httpService.sendRequest(
        connectionService.constructConnectionsForClientRequest(clientKey, relationship, status, clientStatus, pageable, contactResponseCallback),
        connectionCustomErrorHandlers
      );
    } catch (e) {
      console.error(`Failed to fetch contact connections: ${e}`);
    }
  }

  const customErrorHandlers = { 0: () => (loadError.value = true), 500: () => (loadError.value = true) };

  async function initializeConnectionsForClient(clientKey: string) {
    const contact = contactParams();
    const concerned = concernedParams();

    // Then, fetch the rest of the connections
    await batchService.sendBatchRequest(
      [
        connectionService.constructConnectionsForClientRequest(clientKey, contact.relationship, contact.status, contact.clientStatus, contact.pageable, contactResponseCallback),
        connectionService.constructConnectionsForClientRequest(clientKey, concerned.relationship, concerned.status, concerned.clientStatus, concerned.pageable, concernedResponseCallback),
      ],
      customErrorHandlers
    );
  }

  async function initializeConnectionsForContact() {
    const monitored = monitoredParams();
    const archived = archivedParams();

    const customErrorHandlers = { 0: () => (loadError.value = true), 500: () => (loadError.value = true) };

    // Load in the invitations separately to display first in the UI
    await getInvitations(0, customErrorHandlers);

    // Then, fetch the rest of the connections
    await batchService.sendBatchRequest(
      [
        connectionService.constructConnectionsForContactRequest(
          ConnectionGroupByType.CLIENT,
          monitored.relationship,
          monitored.status,
          monitored.clientStatus,
          monitored.pageable,
          monitoredResponseCallback
        ),
        connectionService.constructConnectionsForContactRequest(
          ConnectionGroupByType.CLIENT,
          archived.relationship,
          archived.status,
          archived.clientStatus,
          archived.pageable,
          archivedResponseCallback
        ),
      ],
      customErrorHandlers
    );
  }

  return {
    loadError,
    getInvitations,
    initializeConnectionsForClient,
    initializeConnectionsForContact,
    getArchivedConnections,
    getMonitoredConnections,
    getConcernedConnections,
    getContactConnections,
    connectionsPendingPageable,
    connectionsConcernedPageable,
    connectionsContactsPageable,
    connectionsMonitoredPageable,
    connectionsArchivedPageable,
  };
}
