export interface BaseNotification {
  title: string;
  type: NotificationType;
  category: NotificationCategory;
  notificationKey: string;
}

export interface ConnectionInvitationNotification extends BaseNotification {
  connectionKey: string;
  description: string;
}

// Banner notifications, as returned from the API
export interface BannerNotification extends BaseNotification {
  bannerType: BannerNotificationType;

  // An optional button url and text which will be applied to the banner button.
  // These can either come directly from the server, or may be added in the notification service controller
  buttonUrl?: string;
  buttonText?: string;
}

// UI-specific fields, configured in the notification-service-controller
export interface UIBannerNotification extends BannerNotification {
  bannerLocation: BannerNotificationLocation;
}

// The type of banner, which determines the UI styles of the banner
export enum BannerNotificationType {
  UNDEFINED = 0,
  ERROR = 1,
  WARNING = 2,
  INFO = 3,
  SUCCESS = 4,
  LOCKOUT = 5,
}

// A direct mapping of the BE notification types
export enum NotificationType {
  UNDEFINED = 0,
  NO_VALID_PAYMENT_METHOD = 1,
  CONNECTION_INVITE = 2,
  CHANGE_DEVICE = 3,
  ACTIVATE_DEVICE = 4,
}

export enum NotificationCategory {
  UNDEFINED = 0,
  CONNECTION_INVITE = 1,
  BANNER = 2,
}

export enum BannerNotificationLocation {
  GLOBAL = 0,
  HOME = 1,
  CONNECTIONS = 2,
  TOOLTIP = 3,
}
