import type { NavigationGuardNext, RouteLocation, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import { authService, clientService, httpService, userService } from '@/services/services-collection';
import { AppView, StoreAction, StoreMutation } from '@/store/store-types';
import store from '@/store';
import { RouteName } from '@/router/router-types';
import { IconType } from '@soberlink/soberlink-vue-library';

// ----------------------------------------------------------------
// Import: Views
// ----------------------------------------------------------------
import Home from '../views/Home.vue';
import Billing from '../views/Billing.vue';
import Connections from '../views/Connections.vue';
import Logout from '../views/Logout.vue';
import Unauthorized from '../views/Unauthorized.vue';
import ServerOutage from '../views/ServerOutage.vue';
import MyMonitoring from '@/views/MyMonitoring/MyMonitoring.vue';
import Device from '@/views/MyMonitoring/Device.vue';
import Contacts from '@/views/MyMonitoring/Contacts.vue';
import Reports from '@/views/Reports.vue';
import ConnectionDrilldown from '@/components/connections/ConnectionDrilldown.vue';
import { HttpResponse } from '@/services/http/https-service-types';
import { Client } from '@/services/client/client-service-types';

// ----------------------------------------------------------------
// Routes: Init
// ----------------------------------------------------------------
const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: RouteName.HOME,
    component: Home,
    alias: '/home',
    beforeEnter: [routeGuard, () => store.commit(StoreMutation.SET_APP_VIEW, AppView.TOP_LEVEL)],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/connections',
    name: RouteName.CONNECTIONS,
    component: Connections,
    beforeEnter: [routeGuard, () => store.commit(StoreMutation.SET_APP_VIEW, AppView.TOP_LEVEL)],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/connections/:key',
    name: RouteName.CLIENT_DRILLDOWN,
    component: ConnectionDrilldown,
    beforeEnter: [routeGuard, getSelectedDrilldownClient],
    children: [
      {
        path: 'reports',
        name: RouteName.CLIENT_DRILLDOWN_REPORTS,
        component: Reports,
      },
    ],
  },
  {
    path: '/my-monitoring',
    name: RouteName.MY_MONITORING,
    component: MyMonitoring,
    // Set app view in component onBeforeMount to avoid Vue router warnings
    beforeEnter: [routeGuard, hasMyMonitoringPermission],
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ':clientKey',
        name: RouteName.MY_MONITORING,
        component: MyMonitoring,
        children: [
          {
            path: 'device',
            name: RouteName.DEVICE,
            component: Device,
          },
          {
            path: 'reports',
            name: RouteName.MY_MONITORING_REPORTS,
            component: Reports,
          },
          {
            path: 'contacts',
            name: RouteName.CONTACTS,
            component: Contacts,
          },
        ],
      },
    ],
  },
  {
    path: '/unauthorized',
    name: RouteName.UNAUTHORIZED,
    component: Unauthorized,
    beforeEnter: routeGuard,
  },
  {
    path: '/billing',
    name: RouteName.BILLING,
    component: Billing,
    beforeEnter: routeGuard,
  },
  {
    path: '/serveroutage',
    name: RouteName.SERVER_OUTAGE,
    component: ServerOutage,
    beforeEnter: routeGuard,
  },
  {
    path: '/logout',
    name: RouteName.LOGOUT,
    component: Logout,
    beforeEnter: routeGuard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
  handleToastQueryParams(to, next);
});

// ----------------------------------------------------------------
// routeGuard
// ----------------------------------------------------------------
function handleToastQueryParams(to: RouteLocationNormalized, next: NavigationGuardNext) {
  const { toast_message, toast_type, is_toast_dismissable } = to.query;

  // If a toast message is found, display it
  if (toast_message) {
    store.commit(StoreMutation.SHOW_TOAST, {
      toastText: toast_message,
      toastType: toast_type || IconType.INFO,
      dismissable: is_toast_dismissable?.toString()?.toLowerCase() === 'true',
    });

    // Remove any toast-related query params from the URL so that they aren't visible in the browser
    const filteredKeys = ['toast_message', 'toast_type', 'is_toast_dismissable'];
    const filteredQueryParams = Object.keys(to.query)
      .filter((key) => !filteredKeys.includes(key))
      .reduce((res, key) => ((res[key] = to.query[key]), res), {});
    next({ ...to, query: filteredQueryParams });
  } else {
    // Otherwise, proceed as intended
    next();
  }
}

function setAppView(view: AppView) {
  store.commit(StoreMutation.SET_APP_VIEW, view);
}

async function hasMyMonitoringPermission() {
  if (!store.state.myMonitoring?.clientList) {
    await store.dispatch(StoreAction.PREPARE_CLIENT_LIST);
  }

  if (!store.state.permissions.myMonitoring) {
    return RouteName.HOME;
  }
}

// Set the selected connection by way of the route param to ensure this is fetched both while navigating and on load/ reload
async function getSelectedDrilldownClient(to: RouteLocation) {
  const clientKey = to.params?.key as string;
  let client: Client;
  try {
    const responseCallback = (response: HttpResponse<Client>) => (client = response.data);
    await httpService.sendRequest(clientService.constructGetClientByKeyRequest(clientKey, responseCallback));
  } catch (e) {
    console.error(`Failed to fetch selected connection: ${e}`);
  }

  // Redirect to connections if we could not fetch the client data
  if (!client) {
    return { path: RouteName.CONNECTIONS };
  }

  store.commit(StoreMutation.SET_SELECTED_DRILLDOWN_CLIENT, client);

  // Once a client has been successfully fetched, update the app view
  setAppView(AppView.CLIENT_DRILLDOWN);

  // If no drilldown page is specified, go to Reports
  if (to.name === RouteName.CLIENT_DRILLDOWN) {
    return { ...to, name: RouteName.CLIENT_DRILLDOWN_REPORTS };
  }
  return;
}

async function routeGuard(to: any) {
  // Get auth requirements for the route
  const isAuthRequired = !!to.matched?.some((record: any): boolean => {
    return record.meta.requiresAuth;
  });

  // If authentication is required
  if (isAuthRequired === true) {
    // Check authentication status
    let isAuthenticated = false;
    try {
      isAuthenticated = await authService.isAuthenticated();
    } catch (e) {
      return '/serveroutage';
    }

    // If the user is authenticated
    if (isAuthenticated === true) {
      // Get role requirements for route
      const permittedRoles = to.meta.permittedRoles;

      // If permitted roles have been specified
      if (permittedRoles) {
        // Get the user
        try {
          const user = await userService.getUser();
          await authService.updateLogoutUrl(user.LogoutUrl);

          // Get the users roles
          const userRoles = user.Roles;

          // Check that the user's roles against those required to access the `to` path
          if (!userRoles || userRoles.some((roleName: string): boolean => permittedRoles.includes(roleName)) === false) {
            return '/unauthorized';
          }
        } catch (e) {
          console.error('Failed to get user');
          return '/unauthorized';
        }
      }
    } else {
      // If user is not authenticated, do not proceed
      return false;
    }
  }

  // User is authorized
  return true;
}

export default router;
