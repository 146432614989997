import { inject, injectable } from 'inversify';
import 'reflect-metadata';

//-----------------------------------------------
// Http Service
//-----------------------------------------------
import type { IHttpService } from '@/services/http/http-service-interface';
import { HttpMethod, HttpRequest, HttpResponse, TYPES as httpServiceTYPES } from '@/services/http/https-service-types';
import { httpService } from '@/services/services-collection';
import { IUserSettingsService, UserSettings } from '@/services/user-settings/user-settings-service-interface';
import { userSettingsCustomErrorHandlers } from '@/utils/error-handling-utils';

@injectable()
export class UserSettingsService implements IUserSettingsService {
  // --------------------------------------------------
  // Fields
  // --------------------------------------------------

  private _userSettings: UserSettings;

  private _httpService: IHttpService;

  //--------------------------------------------------
  // Constructor
  //--------------------------------------------------

  constructor(@inject(httpServiceTYPES.IHttpService) httpService: IHttpService) {
    this._httpService = httpService;
  }

  //----------------------
  // init
  //----------------------
  async init() {
    const settings = await this.getUserSettings();
    if (!settings) {
      throw new Error('Settings service fails to initialize');
    }
  }

  // --------------------------------------------------
  // async getUserSettings
  // --------------------------------------------------
  async getUserSettings(): Promise<UserSettings> {
    if (!this._userSettings) {
      await httpService.sendRequest(this._constructUserSettingsRequest(), userSettingsCustomErrorHandlers);
    }
    return this._userSettings;
  }

  // --------------------------------------------------
  // constructUserSettingsRequest
  // --------------------------------------------------
  _constructUserSettingsRequest(): HttpRequest {
      const url = '/api/v1/usersettings';

    return {
      url,
      shouldHandleOnError: true,
      method: HttpMethod.GET,
      responseCallback: (response: HttpResponse<UserSettings>) => (this._userSettings = response.data),
    };
  }

  // --------------------------------------------------
  // constructUpdateAutoAcceptInvitesRequest
  // --------------------------------------------------
  constructUpdateAutoAcceptInvitesRequest(autoAccept: boolean): HttpRequest {
      const url = `/api/v1/usersettings/isAutoAcceptInvitation/${autoAccept}`;
    return {
      url,
      method: HttpMethod.PUT,
      responseCallback: async () => {
        // Update the current user settings state with the updated choice
        await this.getUserSettings();
        this._userSettings.userConnectionSettings.isAutoAcceptInvitation = autoAccept;
      },
    };
  }
}
