import { inject, injectable } from 'inversify';
import 'reflect-metadata';

import { Config, ExternalLink, IConfigService } from '@/services/config/config-service-interface';

//-----------------------------------------------
// Http Service
//-----------------------------------------------
import type { IHttpService } from '@/services/http/http-service-interface';
import { HttpMethod, HttpRequest, HttpResponse, TYPES as httpServiceTYPES } from '@/services/http/https-service-types';
import { httpService } from '@/services/services-collection';
import { configCustomErrorHandlers } from '@/utils/error-handling-utils';

@injectable()
export class ConfigService implements IConfigService {
  // --------------------------------------------------
  // Fields
  // --------------------------------------------------
  private _config: Config;
  private _httpService: IHttpService;

  //--------------------------------------------------
  // Constructor
  //--------------------------------------------------

  constructor(@inject(httpServiceTYPES.IHttpService) httpService: IHttpService) {
    this._httpService = httpService;
  }

  //----------------------
  // init
  //----------------------
  async init() {
    const config = await this.getConfig();
    if (!config) {
      throw new Error('Config service failed to initialize');
    }
  }

  // --------------------------------------------------
  // async getConfig
  // --------------------------------------------------
  async getConfig(): Promise<Config> {
    if (!this._config) {
      await httpService.sendRequest(this._constructConfigRequest(), configCustomErrorHandlers);
    }
    return this._config;
  }

  // --------------------------------------------------
  // constructConfigRequest
  // --------------------------------------------------
  _constructConfigRequest(): HttpRequest {
    const url = '/config';

    return {
      url,
      shouldHandleOnError: true,
      method: HttpMethod.GET,
      responseCallback: (response: HttpResponse<Config>) => (this._config = this._formatSettings(response.data)),
    };
  }

  // --------------------------------------------------
  // formatSettings
  // --------------------------------------------------
  _formatSettings(response: any) {
    const config: Config = new Config();

    config.Config.ExternalLinks = response.ExternalLinks;
    config.SessionInactivityThresholdSec = response.SessionInactivityThresholdSec;
    config.InactivityWarningPopupSec = response.InactivityWarningPopupSec;
    config.InactivityWarningLogoutPaddingSec = response.InactivityWarningLogoutPaddingSec;
    config.EnvironmentName = response.EnvironmentName;

    return config;
  }

  // --------------------------------------------------
  // getExternalLinks
  // --------------------------------------------------
  async getExternalLinks(): Promise<ExternalLink[]> {
    const config = await this.getConfig();
    return config.Config.ExternalLinks;
  }
}
