import { HttpRequest } from '@/services/http/https-service-types';

// ----------------------------------------------------------------
// Interface
// ----------------------------------------------------------------

export interface IUserSettingsService {
  // -----------------------
  // Methods
  // -----------------------
  init: () => Promise<void>;
  getUserSettings: () => Promise<UserSettings>;
  constructUpdateAutoAcceptInvitesRequest: (autoAccept: boolean) => HttpRequest;
}

// ----------------------------------------------------------------
// UserSettings
// ----------------------------------------------------------------
export class UserSettings {
  public userConnectionSettings: UserConnectionSettings;

  constructor() {
    this.userConnectionSettings = new UserConnectionSettings();
  }
}

// ----------------------------------------------------------------
// UserConnectionSettings
// ----------------------------------------------------------------
export class UserConnectionSettings {
  public isAutoAcceptInvitation: boolean;

  constructor() {
    this.isAutoAcceptInvitation = false;
  }
}
